/* eslint-disable */
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  CommentId: { input: any; output: any }
  ContactId: { input: any; output: any }
  ContentId: { input: any; output: any }
  Cursor: { input: any; output: any }
  DateTime: { input: any; output: any }
  DiaryPostId: { input: any; output: any }
  Email: { input: any; output: any }
  Language: { input: any; output: any }
  Lexeme: { input: any; output: any }
  Long: { input: any; output: any }
  MessageId: { input: any; output: any }
  UserId: { input: any; output: any }
}

/**
 * Сбросить счётчик новых хитов. Затрагивает только счётчик, непосредственно хиты останутся
 * непросмотренными (см. POST /hitlist/view).
 */
export type GQLAckNewHitsOutput = {
  __typename?: 'AckNewHitsOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Добавить в избранное. */
export type GQLAddToFavOutput = {
  __typename?: 'AddToFavOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Диапазон возраста. */
export type GQLAgeRange = {
  __typename?: 'AgeRange'
  /**
   * Минимальный возраст.
   *
   *
   */
  from: Scalars['Int']['output']
  /**
   * Максимальный возраст.
   *
   *
   */
  to: Scalars['Int']['output']
}

/** Отношение к алкоголю. */
export enum GQLAlcoholAttitude {
  No = 'No',
  Sometimes = 'Sometimes',
  Yes = 'Yes',
}

/** Ответ. */
export type GQLAnswerChatMessage = GQLChatMessage & {
  __typename?: 'AnswerChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Внешность. */
export enum GQLAppearance {
  Asian = 'Asian',
  Black = 'Black',
  Caucasian = 'Caucasian',
  European = 'European',
  Hispanic = 'Hispanic',
  Indian = 'Indian',
  MiddleEastern = 'MiddleEastern',
  MixedRace = 'MixedRace',
  NativeAmerican = 'NativeAmerican',
  Other = 'Other',
}

/** Apple App Store подписка на VIP. */
export type GQLAppStoreVipSubscription = GQLVipSubscription & {
  __typename?: 'AppStoreVipSubscription'
  /**
   * Дата и время экспирации подписки.
   *
   *
   */
  expiresAt: Scalars['DateTime']['output']
}

/** Запрос на добавление интересов. */
export type GQLAskToFillInterestsChatMessage = GQLChatMessage & {
  __typename?: 'AskToFillInterestsChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Прикрепленное фото. */
export type GQLAttachedPhoto = {
  __typename?: 'AttachedPhoto'
  /**
   * URL-ссылки на различные форматы фотографии.
   *
   *
   */
  urls: GQLPhotoUrls
}

/** Сообщение с прикрепленными фото. */
export type GQLAttachedPhotoChatMessage = GQLChatMessage & {
  __typename?: 'AttachedPhotoChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Фото.
   *
   *
   */
  photos: Array<GQLAttachedPhoto>
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Доступные. */
export enum GQLAvailablePromoServices {
  Astrostar = 'ASTROSTAR',
  Teamo = 'TEAMO',
  Viber = 'VIBER',
}

/** Отменить лайк */
export type GQLCancelLikeOutput = {
  __typename?: 'CancelLikeOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Проголосовать за ответы в Опросе */
export type GQLCastNewPollVoteOutput = {
  __typename?: 'CastNewPollVoteOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Проголосовать за ответы в Опросе */
export type GQLCastPollVoteOutput = {
  __typename?: 'CastPollVoteOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Изменить email. */
export type GQLChangeEmailOutput = {
  __typename?: 'ChangeEmailOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Изменить фон. */
export type GQLChangeThemeOutput = {
  __typename?: 'ChangeThemeOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Контакт чата. */
export type GQLChatContact = {
  __typename?: 'ChatContact'
  /**
   * Предполагаемый DateTime удаления контакта
   *
   *
   */
  autoDeleteDate?: Maybe<Scalars['DateTime']['output']>
  /**
   * Идентификатор контакта.
   *
   *
   */
  contactId: Scalars['ContactId']['output']
  /**
   * «Избранный».
   *
   *
   */
  favorite: Scalars['Boolean']['output']
  /**
   * Я в игноре у этого юзера.
   *
   *
   */
  ignoredByThem: Scalars['Boolean']['output']
  /**
   * Переписку начал владелец контакта?.
   *
   *
   */
  initiatedByOwner: Scalars['Boolean']['output']
  /**
   * Пользователь замьючен
   *
   *
   */
  isMutedByMe: Scalars['Boolean']['output']
  /**
   * Последнее сообщение.
   *
   *
   */
  lastMessage?: Maybe<GQLChatMessage>
  /**
   * Количество всех сообщений.
   *
   *
   */
  messagesCount: Scalars['Int']['output']
  /**
   * Количество новых сообщений от контакта.
   *
   *
   */
  newMessagesCount: Scalars['Int']['output']
  /**
   * Профайл.
   *
   *
   */
  profile?: Maybe<GQLPublicProfile>
  /**
   * Контакт с технической поддержкой?
   *
   *
   */
  support: Scalars['Boolean']['output']
  /**
   * Идентификатор пользователя контакта.
   *
   *
   */
  userId: Scalars['UserId']['output']
}

/** Список контактов */
export type GQLChatContactConnection = {
  __typename?: 'ChatContactConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLChatContact>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Список контактов. */
export type GQLChatContactList = {
  __typename?: 'ChatContactList'
  /**
   * Контакты
   *
   *
   */
  contacts: GQLChatContactConnection
}

/** Список контактов. */
export type GQLChatContactListContactsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<GQLContactType>
}

/** Счетчики списка контактов. */
export type GQLChatContactListCounters = {
  __typename?: 'ChatContactListCounters'
  /**
   * Число контактов в списке.
   *
   *
   */
  contactsCount: Scalars['Int']['output']
  /**
   * Суммарное число сообщений от всех контактов из списка.
   *
   *
   */
  messagesCount: Scalars['Int']['output']
  /**
   * Число новых сообщений от всех контактов из списка.
   *
   *
   */
  newMessagesCount: Scalars['Int']['output']
}

/** Счетчики списков контактов. */
export type GQLChatContactListsCounters = {
  __typename?: 'ChatContactListsCounters'
  /**
   * Список «все».
   *
   *
   */
  all: GQLChatContactListCounters
  /**
   * Список «избранное».
   *
   *
   */
  fav: GQLChatContactListCounters
  /**
   * Список «игнорируемые».
   *
   *
   */
  ignored: GQLChatContactListCounters
  /**
   * Список «новые» (когда нет ни одного сообщений в их адрес).
   *
   *
   */
  new: GQLChatContactListCounters
}

/** Лимиты. */
export type GQLChatLimitsType = {
  __typename?: 'ChatLimitsType'
  /**
   * Максимальный суточный лимит новых контактов для VIP юзеров.
   *
   *
   */
  vipDailyMaxContacts: Scalars['Int']['output']
}

/** Сообщение. */
export type GQLChatMessage = {
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Настройки. */
export type GQLChatSettings = {
  __typename?: 'ChatSettings'
  /**
   * Через сколько дней автоудаление удалит невзаимный контакт.
   *
   *
   */
  autodeleteNotMutualContactInDays: Scalars['Int']['output']
  /**
   * Получать сообщения только от верифицированных пользователей.
   *
   *
   */
  messagesOnlyFromVerifiedByPhoto: Scalars['Boolean']['output']
}

/** Наличие детей. */
export enum GQLChildren {
  Apart = 'Apart',
  No = 'No',
  Together = 'Together',
  Want = 'Want',
}

/** Город. */
export type GQLCity = {
  __typename?: 'City'
  /**
   *
   *
   *
   */
  country: GQLCountry
  /**
   * Полное имя, включая метро (если есть)
   *
   *
   */
  fullName: Scalars['String']['output']
  /**
   *
   *
   *
   */
  id: Scalars['ID']['output']
  /**
   *
   *
   *
   */
  name: Scalars['String']['output']
  /**
   *
   *
   *
   */
  region: GQLRegion
}

/** Очистить «игнорирумые». */
export type GQLClearIgnoredOutput = {
  __typename?: 'ClearIgnoredOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Комментарий */
export type GQLComment = {
  __typename?: 'Comment'
  /**
   * Доступные действия с комментарием
   *
   *
   */
  actions: GQLCommentActions
  /**
   * Профиль автора комментария
   *
   *
   */
  author?: Maybe<GQLPublicProfile>
  /**
   * Время создания
   *
   *
   */
  created: Scalars['DateTime']['output']
  /**
   * Данные присущие разным типам комментариев
   *
   *
   */
  details: GQLCommentDetails
  /**
   * Идентификатор комментария
   *
   *
   */
  id: Scalars['CommentId']['output']
  /**
   * Является ли комментарий новым для текущего пользователя
   *
   *
   */
  isNewForMe?: Maybe<Scalars['Boolean']['output']>
  /**
   * Лайкнул ли я этот комментарий
   *
   *
   */
  likedByMe: Scalars['Boolean']['output']
  /**
   * Количество лайков
   *
   *
   */
  likesCount: Scalars['Int']['output']
  /**
   * Родительский комментарий
   *
   *
   */
  parentComment?: Maybe<GQLParentComment>
  /**
   * Время обновления
   *
   *
   */
  updated?: Maybe<Scalars['DateTime']['output']>
}

/** Доступные действия с комментарием */
export type GQLCommentActions = {
  __typename?: 'CommentActions'
  /**
   * Можно удалить комментарий
   *
   *
   */
  delete: Scalars['Boolean']['output']
  /**
   * Можно изменить комментарий
   *
   *
   */
  modify: Scalars['Boolean']['output']
  /**
   * Можно ответить на комментарий
   *
   *
   */
  respond: Scalars['Boolean']['output']
}

/** Данные присущие разным типам комментариев */
export type GQLCommentDetails = {
  /**
   * Тип комментария
   *
   *
   */
  type: GQLCommentType
}

/** Гифт */
export type GQLCommentGift = {
  __typename?: 'CommentGift'
  /**
   * Идентификатор гифта
   *
   *
   */
  id: Scalars['Int']['output']
  /**
   * Изображение гифта
   *
   *
   */
  image: GQLMyGiftImage
  /**
   * Отправитель скрыт?
   *
   *
   */
  senderHidden: Scalars['Boolean']['output']
  /**
   * Отправитель
   *
   *
   */
  senderProfile?: Maybe<GQLPublicProfile>
}

/** Данные для хита о новом комментарии. */
export type GQLCommentHitDetails = GQLHitDetails & {
  __typename?: 'CommentHitDetails'
  /**
   * Идентификатор оставленного комментария.
   *
   *
   */
  commentId: Scalars['ContentId']['output']
  /**
   * Ссылка на прокомментированный контент.
   *
   *
   */
  contentShortcut?: Maybe<GQLContentShortcut>
  /**
   * Тип дополнительных данных.
   *
   *
   */
  type: GQLHitDetailsType
}

/** Комментарии */
export type GQLComments = {
  __typename?: 'Comments'
  /**
   * Комментарии
   *
   *
   * ### Возможные ошибки
   * * invalid_input – Передан неверный аргумент запроса.
   *
   */
  comments: GQLCommentsConnection
  /**
   * Число комментариев
   *
   *
   */
  count: Scalars['Int']['output']
  /**
   * Число новых комментариев
   *
   *
   */
  countNew?: Maybe<Scalars['Int']['output']>
}

/** Комментарии */
export type GQLCommentsCommentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit: Scalars['Int']['input']
}

/** Комментарии */
export type GQLCommentsConnection = {
  __typename?: 'CommentsConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLComment>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Тип комментария */
export enum GQLCommentType {
  /** Комментарий-гифт */
  Gift = 'GIFT',
  /** Комментарий-стикер */
  Sticker = 'STICKER',
  /** Текстовый комментарий */
  Text = 'TEXT',
}

/** Гороскоп совместимости с отправившим запрос пользователем. */
export type GQLCompability = {
  __typename?: 'Compability'
  /**
   * Текст.
   *
   *
   */
  horoscope: Scalars['String']['output']
}

/** Телосложение. */
export enum GQLConstitution {
  Athletic = 'Athletic',
  Normal = 'Normal',
  Sports = 'Sports',
  Stout = 'Stout',
  Thick = 'Thick',
  Thin = 'Thin',
}

/** Тип контакта */
export enum GQLContactType {
  /** Все, кроме игнорируемых */
  All = 'ALL',
  /** Избранные */
  Fav = 'FAV',
  /** Игнорируемые */
  Ignored = 'IGNORED',
  /** Новые (нет ответа с «нашей» стороны) */
  New = 'NEW',
}

/** Объект-ссылка на какой-либо контент. */
export type GQLContentShortcut = {
  __typename?: 'ContentShortcut'
  /**
   * ID автора контента.
   *
   *
   */
  contentAuthorId: Scalars['UserId']['output']
  /**
   * Универсальный идентификатор контента.
   *
   *
   */
  contentId: Scalars['ContentId']['output']
  /**
   * Уникальный ID контента.
   *
   *
   */
  contentSequentialId: Scalars['Int']['output']
  /**
   * Тип контента.
   *
   *
   */
  contentType: GQLContentType
}

/** Тип контента. */
export enum GQLContentType {
  /** Комментарий. */
  Comment = 'COMMENT',
  /** Пост дневника. */
  DiaryPost = 'DIARY_POST',
  /** Пост дневника. */
  NewDiaryPost = 'NEW_DIARY_POST',
  /** Фотография. */
  Photo = 'PHOTO',
  /** Вопрос. */
  Question = 'QUESTION',
}

/** Страна. */
export type GQLCountry = {
  __typename?: 'Country'
  /**
   * Код страны (может отличаться от ISO-кода).
   *
   *
   */
  code: Scalars['String']['output']
  /**
   *
   *
   *
   */
  id: Scalars['ID']['output']
  /**
   * ISO-код страны.
   *
   *
   */
  isoCode: Scalars['String']['output']
  /**
   *
   *
   *
   */
  name: Scalars['String']['output']
}

/** Создать опрос */
export type GQLCreatePollOutput = {
  __typename?: 'CreatePollOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
  /**
   *
   *
   *
   */
  poll: GQLDiaryPoll
}

/** Создать обычный пост */
export type GQLCreateRegularPostOutput = {
  __typename?: 'CreateRegularPostOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
  /**
   *
   *
   *
   */
  post: GQLDiaryRegularPost
}

/** Создать короткий пост */
export type GQLCreateShortPostOutput = {
  __typename?: 'CreateShortPostOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
  /**
   *
   *
   *
   */
  post: GQLDiaryShortPost
}

/** Цели знакомств. */
export enum GQLDatingGoals {
  Email = 'Email',
  Family = 'Family',
  Flirtation = 'Flirtation',
  FlirtationAndDating = 'FlirtationAndDating',
  FriendlyCommunication = 'FriendlyCommunication',
  Friendship = 'Friendship',
  Love = 'Love',
  Meet = 'Meet',
  SeriousRelationship = 'SeriousRelationship',
  Sport = 'Sport',
  Travel = 'Travel',
  WillDecideWhenMeet = 'WillDecideWhenMeet',
}

/** Профиль знакомств. */
export type GQLDatingProfileLexicalDictionary = {
  __typename?: 'DatingProfileLexicalDictionary'
  /**
   * Отношение к алкоголю.
   *
   *
   */
  alcohol: Array<GQLDatingProfileLexicalOptionType>
  /**
   * Внешность.
   *
   *
   */
  appearance: Array<GQLDatingProfileLexicalOptionType>
  /**
   * Наличие детей.
   *
   *
   */
  children: Array<GQLDatingProfileLexicalOptionType>
  /**
   * Телосложение.
   *
   *
   */
  constitution: Array<GQLDatingProfileLexicalOptionType>
  /**
   * Цели знакомств.
   *
   *
   */
  datingGoals: Array<GQLDatingProfileLexicalOptionType>
  /**
   * Образование.
   *
   *
   */
  education: Array<GQLDatingProfileLexicalOptionType>
  /**
   * Место проживания.
   *
   *
   */
  homeStatus: Array<GQLDatingProfileLexicalOptionType>
  /**
   * Языки, которые знает пользователь.
   *
   *
   */
  knownLanguages: Array<GQLDatingProfileLexicalOptionType>
  /**
   * Кого ищу.
   *
   *
   */
  lookFor: Array<GQLDatingProfileLexicalOptionType>
  /**
   * Материальный статус.
   *
   *
   */
  materialStatus: Array<GQLDatingProfileLexicalOptionType>
  /**
   * Ориентация.
   *
   *
   */
  orientation: Array<GQLDatingProfileLexicalOptionType>
  /**
   * Отношение к курению.
   *
   *
   */
  smoking: Array<GQLDatingProfileLexicalOptionType>
}

/** Элемент словаря. */
export type GQLDatingProfileLexicalOptionType = {
  __typename?: 'DatingProfileLexicalOptionType'
  /**
   * Лексема для женской анкеты.
   *
   *
   */
  femaleLexeme?: Maybe<Scalars['String']['output']>
  /**
   * Лексема для анкеты текущего пользователя.
   *
   *
   */
  lexeme?: Maybe<Scalars['String']['output']>
  /**
   * Лексема для мужской анкеты.
   *
   *
   */
  maleLexeme?: Maybe<Scalars['String']['output']>
  /**
   * Значение элемента словаря.
   *
   *
   */
  value?: Maybe<Scalars['String']['output']>
}

/** Варианты профиля знакомств текущего пользователя. */
export type GQLDatingProfileOptions = {
  __typename?: 'DatingProfileOptions'
  /**
   * Отношение к алкоголю.
   *
   *
   */
  alcohol: Array<GQLAlcoholAttitude>
  /**
   * Внешность.
   *
   *
   */
  appearance: Array<GQLAppearance>
  /**
   * Наличие детей.
   *
   *
   */
  children: Array<GQLChildren>
  /**
   * Телосложение.
   *
   *
   */
  constitution: Array<GQLConstitution>
  /**
   * Цели знакомств.
   *
   *
   */
  datingGoals: Array<GQLDatingGoals>
  /**
   * Образование.
   *
   *
   */
  education: Array<GQLEducation>
  /**
   * Место проживания.
   *
   *
   */
  homeStatus: Array<GQLHomeStatus>
  /**
   * Языки, которые знает пользователь.
   *
   *
   */
  knownLanguages: Array<Scalars['Language']['output']>
  /**
   * Кого ищу.
   *
   *
   */
  lookFor: Array<GQLLookFor>
  /**
   * Материальный статус.
   *
   *
   */
  materialStatus: Array<GQLMaterialStatus>
  /**
   * Ориентация.
   *
   *
   */
  orientation: Array<GQLOrientation>
  /**
   * Отношение к курению.
   *
   *
   */
  smoking: Array<GQLSmokingAttitude>
}

/** Удалить пост */
export type GQLDeletePostOutput = {
  __typename?: 'DeletePostOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Дневники */
export type GQLDiaries = {
  __typename?: 'Diaries'
  /**
   * Все посты
   *
   *
   */
  allPosts: GQLRecentDiaryPostsConnection
  /**
   * Дневник пользователя
   *
   *
   */
  diary: GQLDiary
  /**
   * Пост Дневника
   *
   *
   */
  post?: Maybe<GQLDiaryPost>
}

/** Дневники */
export type GQLDiariesAllPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<GQLDiaryPostsFilterInput>
  limit: Scalars['Int']['input']
  sort?: InputMaybe<GQLDiaryPostsSortInput>
}

/** Дневники */
export type GQLDiariesDiaryArgs = {
  userId: Scalars['UserId']['input']
}

/** Дневники */
export type GQLDiariesPostArgs = {
  id: Scalars['DiaryPostId']['input']
}

/** Варианты настроек для Дневников */
export type GQLDiariesOptions = {
  __typename?: 'DiariesOptions'
  /**
   * Фоны для Короткого поста
   *
   *
   */
  shortPostBackgrounds: Array<GQLDiaryPostBackgroundType>
}

/** Дневник пользователя */
export type GQLDiary = {
  __typename?: 'Diary'
  /**
   * Посты Дневника
   *
   *
   */
  posts: GQLDiaryUserPostsConnection
}

/** Дневник пользователя */
export type GQLDiaryPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<GQLDiaryPostsFilterInput>
  limit: Scalars['Int']['input']
  sort?: InputMaybe<GQLDiaryPostsSortInput>
}

/** Запись в дневнике */
export type GQLDiaryPoll = GQLDiaryPost & {
  __typename?: 'DiaryPoll'
  /**
   * Ответы
   *
   *
   */
  answers: Array<GQLDiaryPollAnswer>
  /**
   * Профиль автора поста
   *
   *
   */
  author?: Maybe<GQLPublicProfile>
  /**
   * Текущий пользователь может проголосовать
   *
   *
   */
  canVote: Scalars['Boolean']['output']
  /**
   * Голосование завершено?
   *
   *
   */
  closed: Scalars['Boolean']['output']
  /**
   * Комментарии
   *
   *
   */
  comments: GQLComments
  /**
   * Время завершения голосования
   *
   *
   */
  end?: Maybe<Scalars['DateTime']['output']>
  /**
   *
   *
   *
   */
  id: Scalars['DiaryPostId']['output']
  /**
   * Лайки
   *
   *
   */
  likes: GQLLikes
  /**
   * Можно выбрать несколько ответов
   *
   *
   */
  multiChoice: Scalars['Boolean']['output']
  /**
   * Время публикации
   *
   *
   */
  posted: Scalars['DateTime']['output']
  /**
   * Вопрос
   *
   *
   */
  question: Scalars['String']['output']
  /**
   *
   *
   *
   */
  sequentialId: Scalars['Int']['output']
  /**
   * Время начала голосования
   *
   *
   */
  start: Scalars['DateTime']['output']
  /**
   * Вопрос
   *
   *
   */
  text?: Maybe<Scalars['String']['output']>
  /**
   * Тип поста
   *
   *
   */
  type: GQLDiaryPostType
  /**
   * Время последнего изменения
   *
   *
   */
  updated?: Maybe<Scalars['DateTime']['output']>
  /**
   * Текущий пользователь уже проголосовал
   *
   *
   */
  voted: Scalars['Boolean']['output']
  /**
   * Всего проголосовало
   *
   *
   */
  votesCount: Scalars['Int']['output']
}

/** Ответ на голосование в записи дневника */
export type GQLDiaryPollAnswer = {
  __typename?: 'DiaryPollAnswer'
  /**
   * Идентификатор ответа
   *
   *
   */
  id: Scalars['Int']['output']
  /**
   * Текст ответа
   *
   *
   */
  text: Scalars['String']['output']
  /**
   * Текущий пользователь проголосовал за этот ответ
   *
   *
   */
  voted: Scalars['Boolean']['output']
  /**
   * Проголосовало за этот ответ
   *
   *
   */
  votesCount: Scalars['Int']['output']
}

/** Запись в дневнике */
export type GQLDiaryPost = {
  /**
   * Профиль автора поста
   *
   *
   */
  author?: Maybe<GQLPublicProfile>
  /**
   * Комментарии
   *
   *
   */
  comments: GQLComments
  /**
   *
   *
   *
   */
  id: Scalars['DiaryPostId']['output']
  /**
   * Лайки
   *
   *
   */
  likes: GQLLikes
  /**
   * Время публикации
   *
   *
   */
  posted: Scalars['DateTime']['output']
  /**
   *
   *
   *
   */
  sequentialId: Scalars['Int']['output']
  /**
   * Тип поста
   *
   *
   */
  type: GQLDiaryPostType
  /**
   * Время последнего изменения
   *
   *
   */
  updated?: Maybe<Scalars['DateTime']['output']>
}

/** Фон для поста Дневника */
export type GQLDiaryPostBackgroundType = {
  __typename?: 'DiaryPostBackgroundType'
  /**
   *
   *
   *
   */
  id: Scalars['Int']['output']
  /**
   *
   *
   *
   */
  pngUrl: Scalars['String']['output']
  /**
   *
   *
   *
   */
  svgUrl: Scalars['String']['output']
}

/** Ответ на голосование в записи дневника */
export type GQLDiaryPostImage = {
  __typename?: 'DiaryPostImage'
  /**
   *
   *
   *
   */
  id: Scalars['String']['output']
  /**
   *
   *
   *
   */
  url: GQLDiaryPostPhotoUrl
}

/** Адреса фотографий */
export type GQLDiaryPostPhotoUrl = {
  __typename?: 'DiaryPostPhotoUrl'
  /**
   *
   *
   *
   */
  large: Scalars['String']['output']
  /**
   *
   *
   *
   */
  small: Scalars['String']['output']
}

/** Фильтрация постов в Дневниках */
export type GQLDiaryPostsFilterInput = {
  polls: Scalars['Boolean']['input']
  regular: Scalars['Boolean']['input']
  short: Scalars['Boolean']['input']
}

/** Как сортировать? */
export enum GQLDiaryPostsSortBy {
  /** По числу комментариев */
  Comments = 'COMMENTS',
  /** По дате публикации */
  Created = 'CREATED',
  /** По числу лайков */
  Likes = 'LIKES',
}

/** Сортировка постов в Дневниках */
export type GQLDiaryPostsSortInput = {
  by: GQLDiaryPostsSortBy
  order: GQLSortOrder
}

/** Тип поста */
export enum GQLDiaryPostType {
  /** Опрос */
  Poll = 'POLL',
  /** Обычный пост */
  Regular = 'REGULAR',
  /** Короткий пост */
  Short = 'SHORT',
}

/** Видео */
export type GQLDiaryPostVideo = {
  __typename?: 'DiaryPostVideo'
  /**
   *
   *
   *
   */
  id: Scalars['String']['output']
  /**
   *
   *
   *
   */
  type: GQLDiaryPostVideoType
}

/** Тип видео */
export enum GQLDiaryPostVideoType {
  /** YouTube */
  Youtube = 'YOUTUBE',
}

/** Запись в дневнике */
export type GQLDiaryRegularPost = GQLDiaryPost & {
  __typename?: 'DiaryRegularPost'
  /**
   * Профиль автора поста
   *
   *
   */
  author?: Maybe<GQLPublicProfile>
  /**
   * Комментарии
   *
   *
   */
  comments: GQLComments
  /**
   * Сгенерированный заголовок
   *
   *
   */
  fallbackTitle?: Maybe<Scalars['String']['output']>
  /**
   *
   *
   *
   */
  id: Scalars['DiaryPostId']['output']
  /**
   * Текст
   *
   *
   */
  images: Array<GQLDiaryPostImage>
  /**
   * Лайки
   *
   *
   */
  likes: GQLLikes
  /**
   * Время публикации
   *
   *
   */
  posted: Scalars['DateTime']['output']
  /**
   *
   *
   *
   */
  sequentialId: Scalars['Int']['output']
  /**
   * Текст
   *
   *
   */
  text?: Maybe<Scalars['String']['output']>
  /**
   * Заголовок
   *
   *
   */
  title?: Maybe<Scalars['String']['output']>
  /**
   * Тип поста
   *
   *
   */
  type: GQLDiaryPostType
  /**
   * Время последнего изменения
   *
   *
   */
  updated?: Maybe<Scalars['DateTime']['output']>
  /**
   * Видео
   *
   *
   */
  video?: Maybe<GQLDiaryPostVideo>
}

/** Запись в дневнике */
export type GQLDiaryShortPost = GQLDiaryPost & {
  __typename?: 'DiaryShortPost'
  /**
   * Профиль автора поста
   *
   *
   */
  author?: Maybe<GQLPublicProfile>
  /**
   * Текст
   *
   *
   */
  background?: Maybe<Scalars['Int']['output']>
  /**
   * Комментарии
   *
   *
   */
  comments: GQLComments
  /**
   *
   *
   *
   */
  id: Scalars['DiaryPostId']['output']
  /**
   * Лайки
   *
   *
   */
  likes: GQLLikes
  /**
   * Время публикации
   *
   *
   */
  posted: Scalars['DateTime']['output']
  /**
   *
   *
   *
   */
  sequentialId: Scalars['Int']['output']
  /**
   * Текст
   *
   *
   */
  text?: Maybe<Scalars['String']['output']>
  /**
   * Тип поста
   *
   *
   */
  type: GQLDiaryPostType
  /**
   * Время последнего изменения
   *
   *
   */
  updated?: Maybe<Scalars['DateTime']['output']>
}

/** Все посты пользователя от новых к старым */
export type GQLDiaryUserPostsConnection = {
  __typename?: 'DiaryUserPostsConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLDiaryPost>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Настройки звонков "Не беспокоить". */
export type GQLDndSettings = {
  __typename?: 'DndSettings'
  /**
   * Режим.
   *
   *
   */
  mode: Scalars['String']['output']
  /**
   * Не беспокоить с ...
   *
   *
   */
  timeFrom?: Maybe<GQLTime>
  /**
   * Не беспокоить по ...
   *
   *
   */
  timeTo?: Maybe<GQLTime>
}

/** Изменить обычный пост */
export type GQLEditRegularPostOutput = {
  __typename?: 'EditRegularPostOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
  /**
   *
   *
   *
   */
  post: GQLDiaryRegularPost
}

/** Изменить короткий пост */
export type GQLEditShortPostOutput = {
  __typename?: 'EditShortPostOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
  /**
   *
   *
   *
   */
  post: GQLDiaryShortPost
}

/** Образование. */
export enum GQLEducation {
  College = 'College',
  HigherEducationUniversity = 'HigherEducationUniversity',
  HighSchool = 'HighSchool',
  IncompleteSecondaryEducation = 'IncompleteSecondaryEducation',
  MdPhD = 'MDPhD',
  SomeUniversityCourses = 'SomeUniversityCourses',
  TwoOrMoreUniversityDegrees = 'TwoOrMoreUniversityDegrees',
}

/** Пол пользователя. */
export enum GQLGender {
  F = 'F',
  M = 'M',
}

/** Гифт. */
export type GQLGift = {
  __typename?: 'Gift'
  /**
   * Идентификатор гифта пользователя.
   *
   *
   */
  giftId: Scalars['Int']['output']
  /**
   * Картинка.
   *
   *
   */
  image: GQLMyGiftImage
  /**
   * Сообщение.
   *
   *
   */
  message: Scalars['String']['output']
  /**
   * Гифт отправившего запрос пользователя?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Отправитель скрыт?
   *
   *
   */
  senderHidden: Scalars['Boolean']['output']
  /**
   * Отправитель.
   *
   *
   */
  senderProfile?: Maybe<GQLPublicProfile>
}

/** Сообщение-гифт. */
export type GQLGiftChatMessage = GQLChatMessage & {
  __typename?: 'GiftChatMessage'
  /**
   * Комментарий к подарку.
   *
   *
   */
  comment: Scalars['String']['output']
  /**
   * Изображение.
   *
   *
   */
  image: GQLGiftImageChatMessage
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Данные для комментария-гифта */
export type GQLGiftCommentDetails = GQLCommentDetails & {
  __typename?: 'GiftCommentDetails'
  /**
   * Подарок
   *
   *
   */
  gift?: Maybe<GQLCommentGift>
  /**
   * Тип комментария
   *
   *
   */
  type: GQLCommentType
}

/** Изображение гифта. */
export type GQLGiftImageChatMessage = {
  __typename?: 'GiftImageChatMessage'
  /**
   * Идентификатор (типа «vector_cat»).
   *
   *
   */
  id: Scalars['String']['output']
}

/** Формат URL картинки гифта. */
export type GQLGiftImageFormatType = {
  __typename?: 'GiftImageFormatType'
  /**
   * ID формата картинки.
   *
   *
   */
  formatId: Scalars['Int']['output']
  /**
   * Высота картинки.
   *
   *
   */
  height?: Maybe<Scalars['Int']['output']>
  /**
   * Тип картинки.
   *
   *
   */
  type: GQLGiftImageKind
  /**
   * Шаблон ссылки на картинку. Вместо "%s" нужно подставить "imageName".
   * Пример: https://images.wambacdn.net/images/upload/gifts/120/%s.png
   *
   *
   */
  urlTemplate: Scalars['String']['output']
  /**
   * Ширина картинки.
   *
   *
   */
  width?: Maybe<Scalars['Int']['output']>
}

/** Тип картинки гифта. */
export enum GQLGiftImageKind {
  Gif = 'gif',
  Jpg = 'jpg',
  Png = 'png',
  Svg = 'svg',
}

/** Гифты. */
export type GQLGifts = {
  __typename?: 'Gifts'
  /**
   * Гифты.
   *
   *
   */
  gifts: GQLGiftsConnection
  /**
   * Всего гифтов.
   *
   *
   */
  giftsCount: Scalars['Int']['output']
}

/** Гифты. */
export type GQLGiftsGiftsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit: Scalars['Int']['input']
}

/** Гифты. */
export type GQLGiftsConnection = {
  __typename?: 'GiftsConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLGift>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Google Play подписка на VIP. */
export type GQLGooglePlayVipSubscription = GQLVipSubscription & {
  __typename?: 'GooglePlayVipSubscription'
  /**
   * Дата и время экспирации подписки.
   *
   *
   */
  expiresAt: Scalars['DateTime']['output']
  /**
   * Текущий статус подписки.
   *
   *
   */
  status: GQLGooglePlayVipSubscriptionStatus
}

/** Текущий статус подписки в Google Play. */
export enum GQLGooglePlayVipSubscriptionStatus {
  /** Активна */
  Active = 'ACTIVE',
  /** Отменено продление, но подписка активна */
  Cancelled = 'CANCELLED',
  /** Полностью стухла */
  Expired = 'EXPIRED',
  /** Ожидается оплата */
  GracePeriod = 'GRACE_PERIOD',
  /** Проблемы с оплатой, ожидается оплата */
  Hold = 'HOLD',
  /** Подписка на паузе по воле пользователя */
  Paused = 'PAUSED',
  /** Google изобрел что-то новое */
  Unknown = 'UNKNOWN',
}

/** Хит. */
export type GQLHit = {
  __typename?: 'Hit'
  /**
   * Хитлист профиль.
   *
   *
   */
  author: GQLHitListProfile
  /**
   * Количество хитов.
   *
   *
   */
  count: Scalars['Int']['output']
  /**
   * Дополнительные данные по хиту.
   *
   *
   */
  details?: Maybe<GQLHitDetails>
  /**
   * Является ли автор хита скрытым.
   *
   *
   */
  invisible: Scalars['Boolean']['output']
  /**
   * Таймстамп.
   *
   *
   */
  lastHit: Scalars['DateTime']['output']
  /**
   * «Вчера», «сегодня», etc.
   *
   *
   */
  lastHitHumanDate: Scalars['String']['output']
  /**
   * Является ли хит новым.
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Текстовое представление хита.
   *
   *
   */
  text: Scalars['Lexeme']['output']
  /**
   * Тип хита.
   *
   *
   */
  type: Scalars['String']['output']
}

/** Список хитов. */
export type GQLHitConnection = {
  __typename?: 'HitConnection'
  /**
   * Данные.
   *
   *
   */
  edges: Array<GQLHitEdge>
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLHit>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Счётчик событий хит-листа. */
export type GQLHitCounters = {
  __typename?: 'HitCounters'
  /**
   * Комментарии на пользовательский контент
   *
   *
   */
  comment: Scalars['Int']['output']
  /**
   * Ответы на комментарии
   *
   *
   */
  commentResponse: Scalars['Int']['output']
  /**
   * Добавление в избранное.
   *
   *
   */
  favorites: Scalars['Int']['output']
  /**
   * Переход в анкету из списка подарков.
   *
   *
   */
  gifts: Scalars['Int']['output']
  /**
   * Переход в анкету из хит-листа.
   *
   *
   */
  hitlist: Scalars['Int']['output']
  /**
   * Переход в анкету из сообщений.
   *
   *
   */
  messenger: Scalars['Int']['output']
  /**
   * Лайк фотографии в рейтинге.
   *
   *
   */
  photolike: Scalars['Int']['output']
  /**
   * Переход в анкету из фотолинейки.
   *
   *
   */
  photoline: Scalars['Int']['output']
  /**
   * Переход в анкету из поиска.
   *
   *
   */
  search: Scalars['Int']['output']
  /**
   * Переход в анкету из стримов.
   *
   *
   */
  videostream: Scalars['Int']['output']
}

/** Дополнительные данные для различных типов хитов. */
export type GQLHitDetails = {
  /**
   * Тип дополнительных данных.
   *
   *
   */
  type: GQLHitDetailsType
}

/** Тип дополнительных данных. */
export enum GQLHitDetailsType {
  /** Комментарий. */
  Comment = 'COMMENT',
}

/** Edge. */
export type GQLHitEdge = {
  __typename?: 'HitEdge'
  /**
   * Data.
   *
   *
   */
  node: GQLHit
}

/** Тип события */
export enum GQLHitGroup {
  /** Все */
  All = 'ALL',
  /** Добавления в Избранное */
  Favorites = 'FAVORITES',
  /** Лайки */
  Likes = 'LIKES',
  /** Посещения */
  Visits = 'VISITS',
}

/** Количество хитов по группам. */
export type GQLHitGroupCounters = {
  __typename?: 'HitGroupCounters'
  /**
   * Все хиты.
   *
   *
   */
  all: Scalars['Int']['output']
  /**
   * Добавления в Избранное.
   *
   *
   */
  favorites: Scalars['Int']['output']
  /**
   * Лайки.
   *
   *
   */
  likes: Scalars['Int']['output']
  /**
   * Посещения.
   *
   *
   */
  visits: Scalars['Int']['output']
}

/** Хиты. */
export type GQLHitList = {
  __typename?: 'HitList'
  /**
   * Количество хитов за указанный период для разных групп хитов.
   *
   *
   */
  hitCounters: GQLHitGroupCounters
  /**
   * Хиты
   *
   *
   */
  hits: GQLHitConnection
  /**
   * Лайкеры открыты для пользователя.
   *
   *
   */
  likersRevealed: Scalars['Boolean']['output']
  /**
   * Количество новых хитов.
   *
   *
   */
  newHits: Scalars['Int']['output']
  /**
   * Количество новых хитов для разных групп хитов.
   *
   *
   */
  newHitsCounters: GQLHitGroupCounters
  /**
   * Популярность пользователя.
   *
   *
   */
  popularity: GQLPopularity
  /**
   * Баллы для бесплатного просмотра анкеты.
   *
   *
   */
  specialPoints: GQLHitListSpecialPoints
}

/** Хиты. */
export type GQLHitListHitCountersArgs = {
  period: GQLHitListPeriod
}

/** Хиты. */
export type GQLHitListHitsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  group?: InputMaybe<GQLHitGroup>
  limit?: InputMaybe<Scalars['Int']['input']>
  period: GQLHitListPeriod
}

/** Период хитлиста */
export enum GQLHitListPeriod {
  /** День */
  Day = 'DAY',
  /** Месяц */
  Month = 'MONTH',
  /** Неделя */
  Week = 'WEEK',
}

/** Хит лист профиль. */
export type GQLHitListProfile = {
  __typename?: 'HitListProfile'
  /**
   * Удалена ли анкета пользователя.
   *
   *
   */
  deleted: Scalars['Boolean']['output']
  /**
   * Зашифрованный идентификатор пользователя, который следует отправлять в POST /hitlist/view
   * чтобы зафиксировать факт просмотра хита от этого пользователя.
   *
   *
   */
  encryptedId: Scalars['String']['output']
  /**
   * Главная фотография, если есть.
   *
   *
   */
  photo?: Maybe<GQLPhoto>
  /**
   * Профиль автора хита.
   *
   *
   */
  profile?: Maybe<GQLPublicProfile>
}

/** Баллы для бесплатного просмотра анкеты. */
export type GQLHitListSpecialPoints = {
  __typename?: 'HitListSpecialPoints'
  /**
   * Активен ли сервис для пользователя в данный момент.
   *
   *
   */
  active: Scalars['Boolean']['output']
  /**
   * Количество баллов.
   *
   *
   */
  points?: Maybe<Scalars['Int']['output']>
}

/** Место проживания. */
export enum GQLHomeStatus {
  Along = 'Along',
  Family = 'Family',
  Friend = 'Friend',
  Public = 'Public',
  Punk = 'Punk',
}

/** Игнорировать контакты. */
export type GQLIgnoreContactsOutput = {
  __typename?: 'IgnoreContactsOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Запрос доступа к заблюренным фотографиям. */
export type GQLIncognitoRequestChatMessage = GQLChatMessage & {
  __typename?: 'IncognitoRequestChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Ответ на запрос доступа к заблюренным фотографиям. */
export type GQLIncognitoResponseChatMessage = GQLChatMessage & {
  __typename?: 'IncognitoResponseChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Сообщение о длительности завершенного звонка */
export type GQLInformationAboutCallChatMessage = GQLChatMessage & {
  __typename?: 'InformationAboutCallChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Дневники */
export type GQLLegacyDiaries = {
  __typename?: 'LegacyDiaries'
  /**
   * Дневник пользователя
   *
   *
   */
  diary: GQLLegacyDiary
  /**
   * Новые записи в дневниках
   *
   *
   */
  latestPosts: GQLLegacyLatestDiaryPostsConnection
  /**
   * Содержащие указанный тег записи
   *
   *
   */
  taggedPosts: GQLLegacyTaggedDiaryPostsConnection
  /**
   * Лучшие записи в дневниках
   *
   *
   */
  topPosts: Array<GQLLegacyDiaryPost>
  /**
   * Лучшие голосования в Дневниках
   *
   *
   */
  topVoting: Array<GQLLegacyDiaryPost>
}

/** Дневники */
export type GQLLegacyDiariesDiaryArgs = {
  userId: Scalars['UserId']['input']
}

/** Дневники */
export type GQLLegacyDiariesLatestPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit: Scalars['Int']['input']
  sort?: InputMaybe<GQLLegacyDiaryPostsOrder>
}

/** Дневники */
export type GQLLegacyDiariesTaggedPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit: Scalars['Int']['input']
  sort?: InputMaybe<GQLLegacyDiaryPostsOrder>
  tag: Scalars['String']['input']
}

/** Дневники */
export type GQLLegacyDiariesTopPostsArgs = {
  limit: Scalars['Int']['input']
}

/** Дневники */
export type GQLLegacyDiariesTopVotingArgs = {
  limit: Scalars['Int']['input']
}

/** Дневник пользователя */
export type GQLLegacyDiary = {
  __typename?: 'LegacyDiary'
  /**
   * Я подписан на комментарии к записям этого дневника
   *
   *
   */
  followedByMe: Scalars['Boolean']['output']
  /**
   * Запись в дневнике
   *
   *
   * ### Возможные ошибки
   * * ignored_user_restriction – Запрашивающий в игноре.
   * * hidden_from_anon – Профиль скрыт от незарегов.
   * * diary_post_not_found – Пост дневника не найден
   *
   */
  post?: Maybe<GQLLegacyDiaryPost>
  /**
   * Все записи
   *
   *
   */
  posts: GQLLegacyDiaryPostsConnection
}

/** Дневник пользователя */
export type GQLLegacyDiaryPostArgs = {
  postId: Scalars['Int']['input']
}

/** Дневник пользователя */
export type GQLLegacyDiaryPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit: Scalars['Int']['input']
}

/** Запись в дневнике */
export type GQLLegacyDiaryPost = {
  __typename?: 'LegacyDiaryPost'
  /**
   * Профиль автора
   *
   *
   */
  author?: Maybe<GQLPublicProfile>
  /**
   * Комментарии
   *
   *
   */
  comments: GQLComments
  /**
   * Комментарии отключены
   *
   *
   */
  commentsDisabled: Scalars['Boolean']['output']
  /**
   * Универсальный идентификатор контента
   *
   *
   */
  contentId: Scalars['String']['output']
  /**
   * Я подписан на комментарии к этой записи
   *
   *
   */
  followedByMe: Scalars['Boolean']['output']
  /**
   * Идентификатор поста
   *
   *
   */
  id: Scalars['Int']['output']
  /**
   * Всего лайков
   *
   *
   */
  likesCount: Scalars['Int']['output']
  /**
   * Время публикации
   *
   *
   */
  posted: Scalars['DateTime']['output']
  /**
   * Список тегов
   *
   *
   */
  tags: Array<GQLLegacyDiaryTagPost>
  /**
   * Текст
   *
   *
   */
  text: Scalars['String']['output']
  /**
   * Заголовок
   *
   *
   */
  title?: Maybe<Scalars['String']['output']>
  /**
   * Время последнего изменения
   *
   *
   */
  updated?: Maybe<Scalars['DateTime']['output']>
  /**
   * Голосование
   *
   *
   */
  voting?: Maybe<GQLLegacyDiaryPostVoting>
}

/** Записи из дневника пользователя */
export type GQLLegacyDiaryPostsConnection = {
  __typename?: 'LegacyDiaryPostsConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLLegacyDiaryPost>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Сортировка постов в дневниках */
export enum GQLLegacyDiaryPostsOrder {
  /** Сначала новые */
  NewFirst = 'NEW_FIRST',
  /** Сначала популярные */
  PopularFirst = 'POPULAR_FIRST',
}

/** Голосование в записи дневника */
export type GQLLegacyDiaryPostVoting = {
  __typename?: 'LegacyDiaryPostVoting'
  /**
   * Список ответов
   *
   *
   */
  answers: Array<GQLLegacyDiaryPostVotingAnswer>
  /**
   * Текущий пользователь может проголосовать?
   *
   *
   */
  canVote: Scalars['Boolean']['output']
  /**
   * Голосование завершено
   *
   *
   */
  closed: Scalars['Boolean']['output']
  /**
   * Дата завешения голосования
   *
   *
   */
  closedAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * Можно выбрать несколько вариантов ответов
   *
   *
   */
  multiAnswer: Scalars['Boolean']['output']
  /**
   * Вопрос
   *
   *
   */
  question: Scalars['String']['output']
  /**
   * Дата начала голосования
   *
   *
   */
  startedAt: Scalars['DateTime']['output']
  /**
   * Всего голосов
   *
   *
   */
  totalVotes: Scalars['Int']['output']
}

/** Ответ на голосование в записи дневника */
export type GQLLegacyDiaryPostVotingAnswer = {
  __typename?: 'LegacyDiaryPostVotingAnswer'
  /**
   * Идентификатор ответа
   *
   *
   */
  id: Scalars['Int']['output']
  /**
   * Текст ответа
   *
   *
   */
  text: Scalars['String']['output']
  /**
   * Проголосовало за этот ответ
   *
   *
   */
  votes: Scalars['Int']['output']
}

/** Тег для записи в дневнике */
export type GQLLegacyDiaryTagPost = {
  __typename?: 'LegacyDiaryTagPost'
  /**
   * Имя тега
   *
   *
   */
  name: Scalars['String']['output']
}

/** Записи, на комментарии к которым я подписан */
export type GQLLegacyFollowedByMeDiaryPostsConnection = {
  __typename?: 'LegacyFollowedByMeDiaryPostsConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLLegacyDiaryPost>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Новые записи в дневниках */
export type GQLLegacyLatestDiaryPostsConnection = {
  __typename?: 'LegacyLatestDiaryPostsConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLLegacyDiaryPost>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Дневники */
export type GQLLegacyMyDiaries = {
  __typename?: 'LegacyMyDiaries'
  /**
   * Мой дневник
   *
   *
   */
  diary: GQLLegacyMyDiary
  /**
   * Записи, на комментарии к которым я подписан
   *
   *
   */
  followed: GQLLegacyFollowedByMeDiaryPostsConnection
  /**
   * Рекомендованые теги
   *
   *
   */
  suggestedTags: Array<GQLLegacyDiaryTagPost>
}

/** Дневники */
export type GQLLegacyMyDiariesFollowedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit: Scalars['Int']['input']
  sort?: InputMaybe<GQLLegacyDiaryPostsOrder>
}

/** Дневники */
export type GQLLegacyMyDiariesSuggestedTagsArgs = {
  limit: Scalars['Int']['input']
}

/** Мой дневник */
export type GQLLegacyMyDiary = {
  __typename?: 'LegacyMyDiary'
  /**
   * Запись в моем дневнике
   *
   *
   * ### Возможные ошибки
   * * diary_post_not_found – Пост дневника не найден
   *
   */
  post?: Maybe<GQLLegacyMyDiaryPost>
  /**
   * Все записи из моего дневника
   *
   *
   */
  posts: GQLLegacyMyDiaryPostsConnection
}

/** Мой дневник */
export type GQLLegacyMyDiaryPostArgs = {
  postId: Scalars['Int']['input']
}

/** Мой дневник */
export type GQLLegacyMyDiaryPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit: Scalars['Int']['input']
}

/** Запись в моем дневнике */
export type GQLLegacyMyDiaryPost = {
  __typename?: 'LegacyMyDiaryPost'
  /**
   * Профиль автора
   *
   *
   */
  author?: Maybe<GQLPublicProfile>
  /**
   * Комментарии
   *
   *
   */
  comments: GQLComments
  /**
   * Комментарии отключены
   *
   *
   */
  commentsDisabled: Scalars['Boolean']['output']
  /**
   * Универсальный идентификатор контента
   *
   *
   */
  contentId: Scalars['String']['output']
  /**
   * Идентификатор поста
   *
   *
   */
  id: Scalars['Int']['output']
  /**
   * Всего лайков
   *
   *
   */
  likesCount: Scalars['Int']['output']
  /**
   * Время публикации
   *
   *
   */
  posted: Scalars['DateTime']['output']
  /**
   * Список тегов
   *
   *
   */
  tags: Array<GQLLegacyDiaryTagPost>
  /**
   * Текст
   *
   *
   */
  text: Scalars['String']['output']
  /**
   * Заголовок
   *
   *
   */
  title?: Maybe<Scalars['String']['output']>
  /**
   * Время последнего изменения
   *
   *
   */
  updated?: Maybe<Scalars['DateTime']['output']>
  /**
   * Голосование
   *
   *
   */
  voting?: Maybe<GQLLegacyDiaryPostVoting>
}

/** Записи из моего Дневника */
export type GQLLegacyMyDiaryPostsConnection = {
  __typename?: 'LegacyMyDiaryPostsConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLLegacyMyDiaryPost>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Записи содержащие указанный тег */
export type GQLLegacyTaggedDiaryPostsConnection = {
  __typename?: 'LegacyTaggedDiaryPostsConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLLegacyDiaryPost>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Словари лексем. */
export type GQLLexicalGlossary = {
  __typename?: 'LexicalGlossary'
  /**
   * Словарь профиля знакомств.
   *
   *
   */
  datingProfile: GQLDatingProfileLexicalDictionary
}

/** Лайкнуть контент */
export type GQLLikeOutput = {
  __typename?: 'LikeOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Лайки */
export type GQLLikes = {
  __typename?: 'Likes'
  /**
   * Текущий пользователь может лайкнуть этот контент
   *
   *
   */
  canLike: Scalars['Boolean']['output']
  /**
   * Количество лайков
   *
   *
   */
  count: Scalars['Int']['output']
  /**
   * Текущий пользователь уже лайкнул этот контент
   *
   *
   */
  liked: Scalars['Boolean']['output']
}

/** Локация. Подразумевается, что она имеет точность до города или до метро. */
export type GQLLocation = {
  __typename?: 'Location'
  /**
   * Город.
   *
   *
   */
  city: GQLCity
  /**
   * Страна.
   *
   *
   */
  country: GQLCountry
  /**
   * Идентификатор локации типа «3159_4312_4400» или «3159_4312_4400_42».
   *
   *
   */
  id: Scalars['ID']['output']
  /**
   * Имя локации («Москва»).
   *
   *
   */
  name: Scalars['String']['output']
  /**
   * Регион.
   *
   *
   */
  region: GQLRegion
}

/** Кого ищу. */
export enum GQLLookFor {
  F = 'F',
  M = 'M',
}

/** Материальный статус. */
export enum GQLMaterialStatus {
  Average = 'Average',
  Huge = 'Huge',
  NonStable = 'NonStable',
  Stable = 'Stable',
  Student = 'Student',
  Unemployed = 'Unemployed',
}

/** Мессенджер. */
export type GQLMessenger = {
  __typename?: 'Messenger'
  /**
   * Контакт.
   *
   *
   */
  contact?: Maybe<GQLChatContact>
  /**
   * Список контактов
   *
   *
   */
  contactsList: GQLChatContactList
  /**
   * Знания о лимитах.
   *
   *
   */
  limits: GQLChatLimitsType
  /**
   * Счетчики списков контактов.
   *
   *
   */
  listsCounters: GQLChatContactListsCounters
  /**
   * Reply rate в процентах
   *
   *
   */
  replyRate?: Maybe<Scalars['Int']['output']>
  /**
   * Настройки мессенджера.
   *
   *
   */
  settings: GQLChatSettings
}

/** Мессенджер. */
export type GQLMessengerContactArgs = {
  userId: Scalars['UserId']['input']
}

/** Пропущенный звонок. */
export type GQLMissedCallChatMessage = GQLChatMessage & {
  __typename?: 'MissedCallChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Список всех мутаций. */
export type GQLMutation = {
  __typename?: 'Mutation'
  /**
   * Добавить в избранное.
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  contactsAddToFav: GQLAddToFavOutput
  /**
   * Очистить «игнорирумые».
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  contactsClearIgnored: GQLClearIgnoredOutput
  /**
   * Игнорировать контакты.
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  contactsIgnoreContacts: GQLIgnoreContactsOutput
  /**
   * Удалить контакты.
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  contactsRemoveContacts: GQLRemoveContactsOutput
  /**
   * Убрать из избранных.
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  contactsRemoveFromFav: GQLRemoveFromFavOutput
  /**
   * Убрать из игнора.
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  contactsRemoveFromIgnore: GQLRemoveFromIgnoreOutput
  /**
   * Проголосовать за ответы в Опросе
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * invalid_post_id – Неверный идентификатор поста
   * * post_not_found – Пост не найден
   * * user_already_voted – Пользователь уже проголосовал в этом опросе
   * * invalid_vote_answers – Неверные ответы
   * * vote_to_closed_diary_poll – Голос отправлен в уже завершенный опрос
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  diariesCastNewPollVote: GQLCastNewPollVoteOutput
  /**
   * Создать опрос
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * post_not_found – Пост не найден
   * * invalid_post_text – Неверный текст
   * * invalid_poll_question – Неверный вопрос
   * * invalid_poll_answers – Неверные ответы
   * * invalid_poll_answer – Неверный ответ
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  diariesCreatePoll: GQLCreatePollOutput
  /**
   * Создать обычный пост
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * post_not_found – Пост не найден
   * * invalid_post_content – Неверный контент
   * * invalid_post_text – Неверный текст
   * * invalid_post_tile – Неверный заголовок
   * * invalid_post_images – Неверные изображения
   * * invalid_post_video – Неверное видео
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  diariesCreateRegularPost: GQLCreateRegularPostOutput
  /**
   * Создать короткий пост
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * post_not_found – Пост не найден
   * * invalid_post_text – Неверный текст
   * * invalid_psot_background – Неверный фон
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  diariesCreateShortPost: GQLCreateShortPostOutput
  /**
   * Удалить пост
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * invalid_post_id – Неверный идентификатор поста
   * * post_not_found – Пост не найден
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  diariesDeletePost: GQLDeletePostOutput
  /**
   * Изменить обычный пост
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * invalid_post_id – Неверный идентификатор поста
   * * post_not_found – Пост не найден
   * * post_edit_denied – Редактирование поста запрещено
   * * invalid_post_content – Неверный контент
   * * invalid_post_text – Неверный текст
   * * invalid_post_tile – Неверный заголовок
   * * invalid_post_images – Неверные изображения
   * * invalid_post_video – Неверное видео
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  diariesEditRegularPost: GQLEditRegularPostOutput
  /**
   * Изменить короткий пост
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * invalid_post_id – Неверный идентификатор поста
   * * post_not_found – Пост не найден
   * * post_edit_denied – Редактирование поста запрещено
   * * invalid_post_text – Неверный текст
   * * invalid_psot_background – Неверный фон
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  diariesEditShortPost: GQLEditShortPostOutput
  /**
   * Загрузить фото
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * invalid_image – Изображение не подходит
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  diariesUploadImage: GQLUploadImageOutput
  /**
   * Изменить email.
   *
   * ### Вочдоги
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * password_is_required – Для действия требуется ввести пароль.
   * * password_is_wrong – Указан неверный пароль.
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  emailChangeEmail: GQLChangeEmailOutput
  /**
   * Сбросить счётчик новых хитов. Затрагивает только счётчик, непосредственно хиты останутся
   * непросмотренными (см. POST /hitlist/view).
   *
   * ### Вочдоги
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   *
   */
  hitlistAckNewHits: GQLAckNewHitsOutput
  /**
   * Использовать балл бесплатного просмотра анкеты в событиях.
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  hitlistUseSpecialPoint: GQLUseSpecialPointOutput
  /**
   * Проголосовать за ответы в Опросе
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * diary_post_not_found – Пост дневника не найден
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  legacyDiariesCastPollVote: GQLCastPollVoteOutput
  /**
   * Отменить лайк
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Проверяет CSRF токен. Токен передается в куках и в заголовоке Csrf-Token.
   *   [csrf]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * like_not_found – Лайк не найден
   * * csrf – Не пройдена проверка CSRF токена!
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  likesCancelLike: GQLCancelLikeOutput
  /**
   * Лайкнуть контент
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Проверяет CSRF токен. Токен передается в куках и в заголовоке Csrf-Token.
   *   [csrf]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * content_not_found – Контент не найден
   * * content_author_like – Авторам нельзя лайкать их контент
   * * csrf – Не пройдена проверка CSRF токена!
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  likesLike: GQLLikeOutput
  /**
   * Обновить координаты пользователя и переселить, если получится
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Проверяет наличие верификации, ЕСЛИ пользователь авторизован (пропускает незарегов).
   *   [real_status]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * invalid_input – Передан невалидный параметр!
   * * city_by_ip_not_found – Не найден город по IP.
   * * city_by_coords_not_found – Не найден город по координатам.
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   * * real_status – Пользователь должен быть верифицирован.
   *
   */
  locationUpdateUserCoords: GQLUpdateUserCoordsOutput
  /**
   * Изменить фон.
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * vip_required – Требуется VIP статус.
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  vipSettingsChangeTheme: GQLChangeThemeOutput
  /**
   * Сбросить фон.
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * vip_required – Требуется VIP статус.
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  vipSettingsResetTheme: GQLResetThemeOutput
  /**
   * Изменить видимость посещённых стран.
   * Указываются идентификаторы только тех стран, для которых была изменена видимость.
   *
   * ### Вочдоги
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  visitedCountriesSetCountriesVisibility: GQLSetCountriesVisibilityOutput
}

/** Список всех мутаций. */
export type GQLMutationContactsAddToFavArgs = {
  usersIds: Array<Scalars['UserId']['input']>
}

/** Список всех мутаций. */
export type GQLMutationContactsIgnoreContactsArgs = {
  usersIds: Array<Scalars['UserId']['input']>
}

/** Список всех мутаций. */
export type GQLMutationContactsRemoveContactsArgs = {
  usersIds: Array<Scalars['UserId']['input']>
}

/** Список всех мутаций. */
export type GQLMutationContactsRemoveFromFavArgs = {
  usersIds: Array<Scalars['UserId']['input']>
}

/** Список всех мутаций. */
export type GQLMutationContactsRemoveFromIgnoreArgs = {
  usersIds: Array<Scalars['UserId']['input']>
}

/** Список всех мутаций. */
export type GQLMutationDiariesCastNewPollVoteArgs = {
  answerIds: Array<Scalars['Int']['input']>
  pollId: Scalars['DiaryPostId']['input']
}

/** Список всех мутаций. */
export type GQLMutationDiariesCreatePollArgs = {
  answers: Array<Scalars['String']['input']>
  multiChoice: Scalars['Boolean']['input']
  question: Scalars['String']['input']
  text?: InputMaybe<Scalars['String']['input']>
}

/** Список всех мутаций. */
export type GQLMutationDiariesCreateRegularPostArgs = {
  images?: InputMaybe<Array<Scalars['Int']['input']>>
  text?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  video?: InputMaybe<Scalars['String']['input']>
}

/** Список всех мутаций. */
export type GQLMutationDiariesCreateShortPostArgs = {
  background?: InputMaybe<Scalars['Int']['input']>
  text: Scalars['String']['input']
}

/** Список всех мутаций. */
export type GQLMutationDiariesDeletePostArgs = {
  postId: Scalars['DiaryPostId']['input']
}

/** Список всех мутаций. */
export type GQLMutationDiariesEditRegularPostArgs = {
  images?: InputMaybe<Array<Scalars['Int']['input']>>
  postId: Scalars['DiaryPostId']['input']
  text?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  video?: InputMaybe<Scalars['String']['input']>
}

/** Список всех мутаций. */
export type GQLMutationDiariesEditShortPostArgs = {
  background?: InputMaybe<Scalars['Int']['input']>
  postId: Scalars['DiaryPostId']['input']
  text: Scalars['String']['input']
}

/** Список всех мутаций. */
export type GQLMutationDiariesUploadImageArgs = {
  image: Scalars['String']['input']
}

/** Список всех мутаций. */
export type GQLMutationEmailChangeEmailArgs = {
  newEmail: Scalars['Email']['input']
  password?: InputMaybe<Scalars['String']['input']>
}

/** Список всех мутаций. */
export type GQLMutationHitlistUseSpecialPointArgs = {
  hitAuthorEncryptedId: Scalars['String']['input']
}

/** Список всех мутаций. */
export type GQLMutationLegacyDiariesCastPollVoteArgs = {
  answerIds: Array<Scalars['Int']['input']>
  pollAuthorId: Scalars['UserId']['input']
  pollId: Scalars['Int']['input']
}

/** Список всех мутаций. */
export type GQLMutationLikesCancelLikeArgs = {
  contentId: Scalars['ContentId']['input']
}

/** Список всех мутаций. */
export type GQLMutationLikesLikeArgs = {
  contentId: Scalars['ContentId']['input']
}

/** Список всех мутаций. */
export type GQLMutationLocationUpdateUserCoordsArgs = {
  lat: Scalars['Float']['input']
  lng: Scalars['Float']['input']
}

/** Список всех мутаций. */
export type GQLMutationVipSettingsChangeThemeArgs = {
  themeId: Scalars['Int']['input']
}

/** Список всех мутаций. */
export type GQLMutationVisitedCountriesSetCountriesVisibilityArgs = {
  hiddenCountriesIds: Array<Scalars['Int']['input']>
  visibleCountriesIds: Array<Scalars['Int']['input']>
}

/** Взаимный лайк. */
export type GQLMutualLikeChatMessage = GQLChatMessage & {
  __typename?: 'MutualLikeChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Данные авторизованного пользователя. */
export type GQLMy = {
  __typename?: 'My'
  /**
   * Баланс.
   *
   *
   */
  balance: Scalars['Float']['output']
  /**
   * Процент заполнения профиля
   *
   *
   */
  completenessPercentage?: Maybe<Scalars['Int']['output']>
  /**
   * Профиль знакомств.
   *
   *
   */
  dating: GQLMyDatingProfile
  /**
   * Новые Дневники
   *
   *
   */
  diaries: GQLMyDiaries
  /**
   * Знакомства
   *
   *
   */
  encounters: GQLMyEncounters
  /**
   * Гифты.
   *
   *
   */
  gifts: GQLMyGifts
  /**
   * Хитлист пользователя.
   *
   *
   */
  hitList: GQLHitList
  /**
   * Идентификатор пользователя.
   *
   *
   */
  id: Scalars['UserId']['output']
  /**
   * Интересы пользователя.
   *
   *
   */
  interests: GQLMyInterests
  /**
   * Дневники
   *
   *
   */
  legacyDiaries: GQLLegacyMyDiaries
  /**
   * Мессенджер.
   *
   *
   */
  messenger: GQLMessenger
  /**
   * Модерация.
   *
   *
   */
  moderation: GQLMyModeration
  /**
   * Фотографии.
   *
   *
   */
  photos: GQLMyPhotos
  /**
   * Профиль пользователя.
   *
   *
   */
  profile: GQLMyProfile
  /**
   * Настройки пользователя.
   *
   *
   */
  settings: GQLMySettings
  /**
   * Шаринг.
   *
   *
   */
  sharing?: Maybe<GQLMySharing>
  /**
   * Данные по teamo.
   *
   *
   */
  teamo?: Maybe<GQLMyTeamo>
  /**
   * Путешествия.
   *
   *
   */
  travelAtlas: GQLMyTravelAtlas
  /**
   * Настройки верификации.
   *
   *
   */
  verification: GQLMyVerification
  /**
   * VIP пользователя активен? (deprecated)
   *
   *
   */
  vip: Scalars['Boolean']['output']
  /**
   * Информация о VIP-статусе пользователя.
   *
   *
   */
  vipInfo: GQLMyVipInfo
}

/** Профиль знакомств. */
export type GQLMyDatingProfile = {
  __typename?: 'MyDatingProfile'
  /**
   * Обо мне.
   *
   *
   */
  aboutMe: Scalars['String']['output']
  /**
   * Отношение к алкоголю.
   *
   *
   */
  alcohol?: Maybe<GQLAlcoholAttitude>
  /**
   * Внешность.
   *
   *
   */
  appearance?: Maybe<GQLAppearance>
  /**
   * Наличие детей.
   *
   *
   */
  children?: Maybe<GQLChildren>
  /**
   * Телосложение.
   *
   *
   */
  constitution?: Maybe<GQLConstitution>
  /**
   * Цели знакомств.
   *
   *
   */
  datingGoals?: Maybe<Array<GQLDatingGoals>>
  /**
   * Образование.
   *
   *
   */
  education?: Maybe<GQLEducation>
  /**
   * Рост в миллиметрах.
   *
   *
   */
  height?: Maybe<Scalars['Int']['output']>
  /**
   * Место проживания.
   *
   *
   */
  homeStatus?: Maybe<GQLHomeStatus>
  /**
   * Языки, которые знает пользователь.
   *
   *
   */
  knownLanguages?: Maybe<Array<Scalars['Language']['output']>>
  /**
   * Кого ищу.
   *
   *
   */
  lookFor: Array<GQLLookFor>
  /**
   * Желаемый возраст.
   *
   *
   */
  lookForAge: GQLAgeRange
  /**
   * Максимальная длина интервала желаемого возраста.
   *
   *
   */
  lookForAgeRangeMaxLength: Scalars['Int']['output']
  /**
   * Материальный статус.
   *
   *
   */
  materialStatus?: Maybe<GQLMaterialStatus>
  /**
   * Ориентация.
   *
   *
   */
  orientation?: Maybe<GQLOrientation>
  /**
   * Отношение к курению.
   *
   *
   */
  smoking?: Maybe<GQLSmokingAttitude>
  /**
   * Вес в граммах.
   *
   *
   */
  weight?: Maybe<Scalars['Int']['output']>
}

/** Дневники для текущего пользователя */
export type GQLMyDiaries = {
  __typename?: 'MyDiaries'
  /**
   * Дневник текущего пользователя
   *
   *
   */
  diary: GQLMyDiary
}

/** Дневник текущего пользователя */
export type GQLMyDiary = {
  __typename?: 'MyDiary'
  /**
   * Запись из Дневника текущего пользователя
   *
   *
   */
  post?: Maybe<GQLMyDiaryPost>
  /**
   * Посты текущего пользователя
   *
   *
   */
  posts: GQLMyDiaryPostsConnection
}

/** Дневник текущего пользователя */
export type GQLMyDiaryPostArgs = {
  id: Scalars['DiaryPostId']['input']
}

/** Дневник текущего пользователя */
export type GQLMyDiaryPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<GQLDiaryPostsFilterInput>
  limit: Scalars['Int']['input']
  sort?: InputMaybe<GQLDiaryPostsSortInput>
}

/** Опрос из Дневника текущего пользователя */
export type GQLMyDiaryPoll = GQLMyDiaryPost & {
  __typename?: 'MyDiaryPoll'
  /**
   * Ответы
   *
   *
   */
  answers: Array<GQLMyDiaryPollAnswer>
  /**
   * Профиль автора поста
   *
   *
   */
  author?: Maybe<GQLPublicProfile>
  /**
   * Текущий пользователь может проголосовать
   *
   *
   */
  canVote: Scalars['Boolean']['output']
  /**
   * Голосование завершено?
   *
   *
   */
  closed: Scalars['Boolean']['output']
  /**
   * Комментарии
   *
   *
   */
  comments: GQLComments
  /**
   * Количество комментариев
   *
   *
   */
  commentsCount: Scalars['Int']['output']
  /**
   * Время завершения голосования
   *
   *
   */
  end?: Maybe<Scalars['DateTime']['output']>
  /**
   *
   *
   *
   */
  id: Scalars['DiaryPostId']['output']
  /**
   * Количество лайков
   *
   *
   */
  likesCount: Scalars['Int']['output']
  /**
   * Можно выбрать несколько ответов
   *
   *
   */
  multiChoice: Scalars['Boolean']['output']
  /**
   * Время публикации
   *
   *
   */
  posted: Scalars['DateTime']['output']
  /**
   * Вопрос
   *
   *
   */
  question: Scalars['String']['output']
  /**
   *
   *
   *
   */
  sequentialId: Scalars['Int']['output']
  /**
   * Время начала голосования
   *
   *
   */
  start: Scalars['DateTime']['output']
  /**
   * Вопрос
   *
   *
   */
  text?: Maybe<Scalars['String']['output']>
  /**
   * Тип поста
   *
   *
   */
  type: GQLDiaryPostType
  /**
   * Время последнего изменения
   *
   *
   */
  updated?: Maybe<Scalars['DateTime']['output']>
  /**
   * Текущий пользователь уже проголосовал
   *
   *
   */
  voted: Scalars['Boolean']['output']
  /**
   * Всего проголосовало
   *
   *
   */
  votesCount: Scalars['Int']['output']
}

/** Ответ на голосование в записи дневника */
export type GQLMyDiaryPollAnswer = {
  __typename?: 'MyDiaryPollAnswer'
  /**
   * Идентификатор ответа
   *
   *
   */
  id: Scalars['Int']['output']
  /**
   * Текст ответа
   *
   *
   */
  text: Scalars['String']['output']
  /**
   * Текущий пользователь проголосовал за этот ответ
   *
   *
   */
  voted?: Maybe<Scalars['Boolean']['output']>
  /**
   * Проголосовало за этот ответ
   *
   *
   */
  votesCount: Scalars['Int']['output']
}

/** Запись из Дневника текущего пользователя */
export type GQLMyDiaryPost = {
  /**
   * Профиль автора поста
   *
   *
   */
  author?: Maybe<GQLPublicProfile>
  /**
   * Комментарии
   *
   *
   */
  comments: GQLComments
  /**
   * Количество комментариев
   *
   *
   */
  commentsCount: Scalars['Int']['output']
  /**
   *
   *
   *
   */
  id: Scalars['DiaryPostId']['output']
  /**
   * Количество лайков
   *
   *
   */
  likesCount: Scalars['Int']['output']
  /**
   * Время публикации
   *
   *
   */
  posted: Scalars['DateTime']['output']
  /**
   *
   *
   *
   */
  sequentialId: Scalars['Int']['output']
  /**
   * Тип поста
   *
   *
   */
  type: GQLDiaryPostType
  /**
   * Время последнего изменения
   *
   *
   */
  updated?: Maybe<Scalars['DateTime']['output']>
}

/** Все посты пользователя */
export type GQLMyDiaryPostsConnection = {
  __typename?: 'MyDiaryPostsConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLMyDiaryPost>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Запись в дневнике */
export type GQLMyDiaryRegularPost = GQLMyDiaryPost & {
  __typename?: 'MyDiaryRegularPost'
  /**
   * Профиль автора поста
   *
   *
   */
  author?: Maybe<GQLPublicProfile>
  /**
   * Комментарии
   *
   *
   */
  comments: GQLComments
  /**
   * Количество комментариев
   *
   *
   */
  commentsCount: Scalars['Int']['output']
  /**
   * Сгенерированный заголовок
   *
   *
   */
  fallbackTitle?: Maybe<Scalars['String']['output']>
  /**
   *
   *
   *
   */
  id: Scalars['DiaryPostId']['output']
  /**
   * Текст
   *
   *
   */
  images: Array<GQLDiaryPostImage>
  /**
   * Количество лайков
   *
   *
   */
  likesCount: Scalars['Int']['output']
  /**
   * Время публикации
   *
   *
   */
  posted: Scalars['DateTime']['output']
  /**
   *
   *
   *
   */
  sequentialId: Scalars['Int']['output']
  /**
   * Текст
   *
   *
   */
  text?: Maybe<Scalars['String']['output']>
  /**
   * Заголовок
   *
   *
   */
  title?: Maybe<Scalars['String']['output']>
  /**
   * Тип поста
   *
   *
   */
  type: GQLDiaryPostType
  /**
   * Время последнего изменения
   *
   *
   */
  updated?: Maybe<Scalars['DateTime']['output']>
  /**
   * Видео
   *
   *
   */
  video?: Maybe<GQLDiaryPostVideo>
}

/** Запись в Дневнике */
export type GQLMyDiaryShortPost = GQLMyDiaryPost & {
  __typename?: 'MyDiaryShortPost'
  /**
   * Профиль автора поста
   *
   *
   */
  author?: Maybe<GQLPublicProfile>
  /**
   * Текст
   *
   *
   */
  background?: Maybe<Scalars['Int']['output']>
  /**
   * Комментарии
   *
   *
   */
  comments: GQLComments
  /**
   * Количество комментариев
   *
   *
   */
  commentsCount: Scalars['Int']['output']
  /**
   *
   *
   *
   */
  id: Scalars['DiaryPostId']['output']
  /**
   * Количество лайков
   *
   *
   */
  likesCount: Scalars['Int']['output']
  /**
   * Время публикации
   *
   *
   */
  posted: Scalars['DateTime']['output']
  /**
   *
   *
   *
   */
  sequentialId: Scalars['Int']['output']
  /**
   * Текст
   *
   *
   */
  text?: Maybe<Scalars['String']['output']>
  /**
   * Тип поста
   *
   *
   */
  type: GQLDiaryPostType
  /**
   * Время последнего изменения
   *
   *
   */
  updated?: Maybe<Scalars['DateTime']['output']>
}

/** Знакомства. */
export type GQLMyEncounters = {
  __typename?: 'MyEncounters'
  /**
   * Госолование.
   *
   *
   */
  voting: GQLMyVoting
}

/** Гифты. */
export type GQLMyGift = {
  __typename?: 'MyGift'
  /**
   * Картинка.
   *
   *
   */
  image: GQLMyGiftImage
  /**
   * Сообщение.
   *
   *
   */
  message: Scalars['String']['output']
  /**
   * Идентификатор гифта пользователя.
   *
   *
   */
  myGiftId: Scalars['Int']['output']
  /**
   * Отправитель скрыт?
   *
   *
   */
  senderHidden: Scalars['Boolean']['output']
  /**
   * Отправитель.
   *
   *
   */
  senderProfile?: Maybe<GQLPublicProfile>
}

/** Картинка гифта. */
export type GQLMyGiftImage = {
  __typename?: 'MyGiftImage'
  /**
   * Доступные форматы.
   *
   *
   */
  formats: Array<Scalars['Int']['output']>
  /**
   * Идентификатор картинки (типа «vector_dog»).
   *
   *
   */
  name: Scalars['String']['output']
}

/** Гифты. */
export type GQLMyGifts = {
  __typename?: 'MyGifts'
  /**
   * Гифты.
   *
   *
   */
  gifts: GQLMyGiftsConnection
  /**
   * Всего гифтов.
   *
   *
   */
  giftsCount: Scalars['Int']['output']
}

/** Гифты. */
export type GQLMyGiftsGiftsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit: Scalars['Int']['input']
}

/** Гифты. */
export type GQLMyGiftsConnection = {
  __typename?: 'MyGiftsConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLMyGift>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Интерес пользователя. */
export type GQLMyInterest = {
  __typename?: 'MyInterest'
  /**
   * Идентификатор интереса.
   *
   *
   */
  interestId: Scalars['Int']['output']
  /**
   * Текст интереса.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Интересы пользователя. */
export type GQLMyInterests = {
  __typename?: 'MyInterests'
  /**
   * Интересы пользователя.
   *
   *
   */
  interests: GQLMyInterestsConnection
  /**
   * Всего интересов.
   *
   *
   */
  interestsCount: Scalars['Int']['output']
}

/** Интересы пользователя. */
export type GQLMyInterestsInterestsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit: Scalars['Int']['input']
}

/** Интересы пользователя. */
export type GQLMyInterestsConnection = {
  __typename?: 'MyInterestsConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLMyInterest>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Основные настройки. */
export type GQLMyMainSettings = {
  __typename?: 'MyMainSettings'
  /**
   * Настройка для сервиса звонков "Не беспокоить".
   *
   *
   */
  dndSettings?: Maybe<GQLDndSettings>
  /**
   * Текущий язык пользователя для уведомлений (и для web).
   *
   *
   */
  language: Scalars['Language']['output']
}

/** Модерация. */
export type GQLMyModeration = {
  __typename?: 'MyModeration'
  /**
   * Приветствие отклонено?
   *
   *
   */
  aboutMeRejected: Scalars['Boolean']['output']
}

/** Фотография. */
export type GQLMyPhoto = {
  __typename?: 'MyPhoto'
  /**
   * Комментарии
   *
   *
   */
  comments: GQLComments
  /**
   * Координаты центра лица.
   *
   *
   */
  faceCenter?: Maybe<GQLPhotoFaceCenter>
  /**
   * Идентификатор фото.
   *
   *
   */
  id: Scalars['Long']['output']
  /**
   * Главное фото?
   *
   *
   */
  main: Scalars['Boolean']['output']
  /**
   * Информация о модерации фото.
   *
   *
   */
  moderation: GQLMyPhotoModeration
  /**
   * Размеры фотографии.
   *
   *
   */
  sizes: GQLPhotoSizes
  /**
   * URL-ссылки на различные форматы фотографии.
   *
   *
   */
  urls: GQLPhotoUrls
  /**
   * Верифицировано ли фото?
   *
   *
   */
  verified: Scalars['Boolean']['output']
}

/** Фотография. */
export type GQLMyPhotoModeration = {
  __typename?: 'MyPhotoModeration'
  /**
   * Статус модерации фото
   *
   *
   */
  status: GQLMyPhotoModerationStatus
}

/** Статус модерации фото. */
export enum GQLMyPhotoModerationStatus {
  /** Фото успешно прошло модерацию */
  Approved = 'APPROVED',
  /** Фото ожидает модерации */
  Pending = 'PENDING',
  /** Фото отклонено */
  Rejected = 'REJECTED',
}

/** Фотографии. */
export type GQLMyPhotos = {
  __typename?: 'MyPhotos'
  /**
   * Главная фотография.
   *
   *
   */
  default?: Maybe<GQLMyPhoto>
  /**
   * Фотография
   *
   *
   */
  photo?: Maybe<GQLMyPhoto>
  /**
   * Фотографии.
   *
   *
   */
  photos: GQLMyPhotosConnection
  /**
   * Количество фотографий.
   *
   *
   */
  photosCount: Scalars['Int']['output']
}

/** Фотографии. */
export type GQLMyPhotosPhotoArgs = {
  photoId: Scalars['Long']['input']
}

/** Фотографии. */
export type GQLMyPhotosPhotosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit: Scalars['Int']['input']
}

/** Фотография. */
export type GQLMyPhotosConnection = {
  __typename?: 'MyPhotosConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLMyPhoto>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Статус запроса на верификацию фото. */
export enum GQLMyPhotoVerificationRequestStatus {
  Approved = 'approved',
  Declined = 'declined',
  Moderation = 'moderation',
  NotStarted = 'not_started',
}

/** Информация о подаренном пользователю VIP. */
export type GQLMyPresentVip = {
  __typename?: 'MyPresentVip'
  /**
   * Количество дней, на которое был подарен VIP.
   *
   *
   */
  days: Scalars['Int']['output']
  /**
   * Является ли подарок анонимным.
   *
   *
   */
  hidden: Scalars['Boolean']['output']
  /**
   * Профиль подарившей анкеты.
   *
   *
   */
  presenter?: Maybe<GQLPublicProfile>
}

/** Профиль пользователя. */
export type GQLMyProfile = {
  __typename?: 'MyProfile'
  /**
   * Возраст.
   *
   *
   */
  age: Scalars['Int']['output']
  /**
   * Дата рождения в формате `yyyy-mm-dd`.
   *
   *
   */
  birthDate: Scalars['String']['output']
  /**
   * На этот адрес приходят уведомления.
   *
   *
   */
  email: Scalars['Email']['output']
  /**
   * Пол.
   *
   *
   */
  gender: GQLGender
  /**
   * Местоположение пользователя.
   *
   *
   */
  location: GQLLocation
  /**
   * Имя.
   *
   *
   */
  name: Scalars['String']['output']
  /**
   * Знак зодиака.
   *
   *
   */
  zodiacSign: GQLZodiacSign
}

/** Настройки пользователя. */
export type GQLMySettings = {
  __typename?: 'MySettings'
  /**
   * Основные настройки.
   *
   *
   */
  main: GQLMyMainSettings
  /**
   * Настройки VIP.
   *
   *
   */
  vip: GQLMyVipSettings
}

/** Шаринг. */
export type GQLMySharing = {
  __typename?: 'MySharing'
  /**
   * Виджеты.
   *
   *
   */
  widgets?: Maybe<GQLMyWidgets>
}

/** Результаты по teamo. */
export type GQLMyTeamo = {
  __typename?: 'MyTeamo'
  /**
   * Результаты тестирования на teamo.
   *
   *
   */
  surveyResults?: Maybe<Array<GQLTeamoPeculiarity>>
}

/** Путешествия. */
export type GQLMyTravelAtlas = {
  __typename?: 'MyTravelAtlas'
  /**
   * Посещённые страны.
   *
   *
   */
  visitedCountries: GQLMyTravelAtlasVisitedCountries
}

/** Посещённые страны. */
export type GQLMyTravelAtlasVisitedCountries = {
  __typename?: 'MyTravelAtlasVisitedCountries'
  /**
   * Список стран.
   *
   *
   */
  visitedCountries: GQLMyTravelAtlasVisitedCountriesConnection
  /**
   * Общее количество стран.
   *
   *
   */
  visitedCountriesCount: Scalars['Int']['output']
}

/** Посещённые страны. */
export type GQLMyTravelAtlasVisitedCountriesVisitedCountriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit: Scalars['Int']['input']
}

/** Посещённые страны. */
export type GQLMyTravelAtlasVisitedCountriesConnection = {
  __typename?: 'MyTravelAtlasVisitedCountriesConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLMyTravelAtlasVisitedCountry>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Посещённые страны. */
export type GQLMyTravelAtlasVisitedCountry = {
  __typename?: 'MyTravelAtlasVisitedCountry'
  /**
   * Страна.
   *
   *
   */
  country: GQLCountry
  /**
   * Дата посещения.
   *
   *
   */
  date: Scalars['String']['output']
  /**
   * Видимость для других пользователей.
   *
   *
   */
  visible: Scalars['Boolean']['output']
}

/** Настройки верификации. */
export type GQLMyVerification = {
  __typename?: 'MyVerification'
  /**
   * Является ли анкета пользователя подтверждённой (реал).
   *
   *
   */
  accountVerified: Scalars['Boolean']['output']
  /**
   * Есть ли у анкеты подтвержденный email
   *
   *
   */
  emailVerified: Scalars['Boolean']['output']
  /**
   * Есть ли подтвержденные фотографии?
   *
   *
   */
  photosVerified: Scalars['Boolean']['output']
  /**
   * Статус запроса на верификацию фото.
   *
   *
   */
  photoVerificationRequestStatus: GQLMyPhotoVerificationRequestStatus
}

/** VIP пользователя. */
export type GQLMyVipInfo = {
  __typename?: 'MyVipInfo'
  /**
   * Активен ли вип в данный момент?
   *
   *
   */
  active: Scalars['Boolean']['output']
  /**
   * Информация о подаренном пользователю VIP, если он был подарен, или null в противном случае
   *
   *
   */
  presentVip?: Maybe<GQLMyPresentVip>
  /**
   * Список подписок на VIP
   *
   *
   */
  subscriptions: Array<GQLVipSubscription>
  /**
   * Подписка, которую нельзя автоматически продлить.
   *
   *
   */
  subscriptionWithRenewingIssue?: Maybe<GQLVipSubscription>
}

/** VIP пользователя. */
export type GQLMyVipInfoSubscriptionsArgs = {
  paymentType?: InputMaybe<GQLVipSubscriptionPaymentType>
}

/**
 * Настройки VIP. ВАЖНО! Настройки будут отображаться даже если они не активны,
 *                                 для проверки доступности нужно проверять active
 */
export type GQLMyVipSettings = {
  __typename?: 'MyVipSettings'
  /**
   * Активны ли настройки.
   *
   *
   */
  active: Scalars['Boolean']['output']
  /**
   * ID фона.
   *
   *
   */
  themeId?: Maybe<Scalars['Int']['output']>
}

/** Голосование */
export type GQLMyVoting = {
  __typename?: 'MyVoting'
  /**
   * Лимиты
   *
   *
   */
  limits: GQLMyVotingLimits
}

/** Ограничения для голосования. */
export type GQLMyVotingLimits = {
  __typename?: 'MyVotingLimits'
  /**
   * Максимальное количество голосов в день.
   *
   *
   */
  dailyLimit: Scalars['Int']['output']
  /**
   * Время (в секундах) до окончания периода голосования.
   *
   *
   */
  limitRenewalIn: Scalars['Int']['output']
  /**
   * Количество отправленных голосов за текущий день.
   *
   *
   */
  numberOfVotesMade: Scalars['Int']['output']
}

/** Виджеты. */
export type GQLMyWidgets = {
  __typename?: 'MyWidgets'
  /**
   * Виджет This is me.
   *
   *
   */
  thisIsMe?: Maybe<GQLThisIsMeWidget>
}

/** Онлайн. */
export type GQLOnline = {
  __typename?: 'Online'
  /**
   * Текст типа «была вчера».
   *
   *
   */
  description: Scalars['Lexeme']['output']
  /**
   * Онлайн?
   * Статуса может не быть (null), если он скрыт.
   *
   *
   */
  status: Scalars['Boolean']['output']
}

/** Ориентация. */
export enum GQLOrientation {
  Bi = 'Bi',
  Gay = 'Gay',
  Getero = 'Getero',
  Lesbi = 'Lesbi',
  Other = 'Other',
}

/**
 * Информация для постраничной навигации.
 * См. https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
 */
export type GQLPageInfo = {
  __typename?: 'PageInfo'
  /**
   *
   *
   *
   */
  endCursor: Scalars['Cursor']['output']
  /**
   *
   *
   *
   */
  hasNextPage: Scalars['Boolean']['output']
}

/** Родительский комментарий */
export type GQLParentComment = {
  __typename?: 'ParentComment'
  /**
   * Профиль автора комментария
   *
   *
   */
  author?: Maybe<GQLPublicProfile>
  /**
   * Данные присущие разным типам комментариев
   *
   *
   */
  details: GQLCommentDetails
  /**
   * Идентификатор комментария
   *
   *
   */
  id: Scalars['CommentId']['output']
}

/** Фотография. */
export type GQLPhoto = {
  __typename?: 'Photo'
  /**
   * Комментарии
   *
   *
   */
  comments: GQLComments
  /**
   * Координаты центра лица.
   *
   *
   */
  faceCenter?: Maybe<GQLPhotoFaceCenter>
  /**
   * Высота оригинала фото
   *
   *
   */
  height: Scalars['Int']['output']
  /**
   * Идентификатор фото.
   *
   *
   */
  id: Scalars['Long']['output']
  /**
   * Залайкал ли юзер данное фото
   *
   *
   */
  liked: Scalars['Boolean']['output']
  /**
   * Количество лайков
   *
   *
   */
  likes: Scalars['Int']['output']
  /**
   * Главное фото?
   *
   *
   */
  main: Scalars['Boolean']['output']
  /**
   * Является ли фото портретом
   *
   *
   */
  portrait: Scalars['Boolean']['output']
  /**
   * URL-ссылки на различные форматы фотографии.
   *
   *
   */
  urls: GQLPhotoUrls
  /**
   * Верифицировано ли фото?
   *
   *
   */
  verified: Scalars['Boolean']['output']
  /**
   * Участвует ли фото в голосовании (можно ли за него вообще голосовать)
   *
   *
   */
  voteable: Scalars['Boolean']['output']
  /**
   * Голосовал ли юзер за данное фото (лайк или дизлайк)
   *
   *
   */
  voted: Scalars['Boolean']['output']
  /**
   * Ширина оригинала фото
   *
   *
   */
  width: Scalars['Int']['output']
}

/** Комментарий к фото. */
export type GQLPhotoCommentChatMessage = GQLChatMessage & {
  __typename?: 'PhotoCommentChatMessage'
  /**
   * Комментарий.
   *
   *
   */
  comment: Scalars['String']['output']
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Фото.
   *
   *
   */
  photo: GQLPhoto
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Координаты центра лица. */
export type GQLPhotoFaceCenter = {
  __typename?: 'PhotoFaceCenter'
  /**
   * Координаты носа на кропе HUGE.
   *
   *
   */
  huge?: Maybe<GQLPixelPoint>
  /**
   * Координаты носа на кропе SQUARE.
   *
   *
   */
  square?: Maybe<GQLPixelPoint>
  /**
   * Координаты носа на кропе SQUARE_LARGE.
   *
   *
   */
  squareLarge?: Maybe<GQLPixelPoint>
  /**
   * Координаты носа на кропе XHUGE.
   *
   *
   */
  xHuge?: Maybe<GQLPixelPoint>
}

/** Размеры фотографии. */
export type GQLPhotoSize = {
  __typename?: 'PhotoSize'
  /**
   * Высота фотографии.
   *
   *
   */
  height: Scalars['Int']['output']
  /**
   * Ширина фотографии.
   *
   *
   */
  width: Scalars['Int']['output']
}

/** Размеры фотографии. */
export type GQLPhotoSizes = {
  __typename?: 'PhotoSizes'
  /**
   * Размеры фотографии типа huge.
   *
   *
   */
  huge: GQLPhotoSize
}

/** URL-ссылки на различные форматы фотографии. */
export type GQLPhotoUrls = {
  __typename?: 'PhotoUrls'
  /**
   * Фото с изначальными пропорциями и сторонами не превышающими 1000x750.
   *
   *
   */
  huge: Scalars['String']['output']
  /**
   * Квадратный кроп 300x300.
   *
   *
   */
  square: Scalars['String']['output']
  /**
   * Квадратный кроп 600x600.
   *
   *
   */
  squareLarge: Scalars['String']['output']
  /**
   * Квадратный кроп 150x150.
   *
   *
   */
  squareSmall: Scalars['String']['output']
  /**
   * Фото с изначальными пропорциями и сторонами не превышающими 1600x1200.
   *
   *
   */
  xHuge?: Maybe<Scalars['String']['output']>
}

/** Целочисленные координаты. */
export type GQLPixelPoint = {
  __typename?: 'PixelPoint'
  /**
   * X-координата.
   *
   *
   */
  x: Scalars['Int']['output']
  /**
   * Y-координата.
   *
   *
   */
  y: Scalars['Int']['output']
}

/** Популярность пользователя. */
export type GQLPopularity = {
  __typename?: 'Popularity'
  /**
   * Хиты за день.
   *
   *
   */
  dailyCounters: GQLHitCounters
  /**
   * Хиты за месяц.
   *
   *
   */
  monthlyCounters: GQLHitCounters
  /**
   * Статус популярности.
   *
   *
   */
  rank: GQLPopularityRank
  /**
   * Хиты за неделю.
   *
   *
   */
  weeklyCounters: GQLHitCounters
}

/** Статус популярности. */
export enum GQLPopularityRank {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
}

/** Запуск приватного стрима. */
export type GQLPrivateStreamChatMessage = GQLChatMessage & {
  __typename?: 'PrivateStreamChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Фотографии. */
export type GQLProfilePhotos = {
  __typename?: 'ProfilePhotos'
  /**
   * Главная фотография.
   *
   *
   */
  default?: Maybe<GQLPhoto>
  /**
   * Фотография
   *
   *
   */
  photo?: Maybe<GQLPhoto>
  /**
   * Фотографии из Омниальбома
   *
   *
   */
  photos: GQLPublicPhotosConnection
  /**
   * Количество фотографий.
   *
   *
   */
  photosCount: Scalars['Int']['output']
}

/** Фотографии. */
export type GQLProfilePhotosPhotoArgs = {
  photoId: Scalars['Long']['input']
}

/** Фотографии. */
export type GQLProfilePhotosPhotosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit: Scalars['Int']['input']
}

/** Отношения между профилями. */
export type GQLProfileRelations = {
  __typename?: 'ProfileRelations'
  /**
   * Совместимость пользователей
   *
   * ### Вочдоги
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   *
   * ### Возможные ошибки
   * * auth – Требуется авторизация.
   *
   */
  compatibility?: Maybe<GQLCompability>
  /**
   * Контакт, если есть.
   *
   *
   */
  contact?: Maybe<GQLChatContact>
  /**
   * Замьючен ли текущим пользователем?
   *
   *
   */
  mutedByMe: Scalars['Boolean']['output']
  /**
   * Space-time location.
   *
   *
   */
  spaceTimeLocation?: Maybe<GQLSpaceTimeLocationRelation>
  /**
   * Подмигнул ли пользователю?
   *
   *
   */
  winkedByMe: Scalars['Boolean']['output']
}

/** Промо сервисы. */
export type GQLPromoServices = {
  __typename?: 'PromoServices'
  /**
   * Доступные.
   *
   *
   */
  available: Array<GQLAvailablePromoServices>
}

/** Профиль знакомств. */
export type GQLPublicDatingProfile = {
  __typename?: 'PublicDatingProfile'
  /**
   * Обо мне.
   *
   *
   */
  aboutMe: Scalars['String']['output']
  /**
   * Отношение к алкоголю.
   *
   *
   */
  alcohol?: Maybe<GQLAlcoholAttitude>
  /**
   * Внешность.
   *
   *
   */
  appearance?: Maybe<GQLAppearance>
  /**
   * Наличие детей.
   *
   *
   */
  children?: Maybe<GQLChildren>
  /**
   * Телосложение.
   *
   *
   */
  constitution?: Maybe<GQLConstitution>
  /**
   * Цели знакомств.
   *
   *
   */
  datingGoals?: Maybe<Array<GQLDatingGoals>>
  /**
   * Образование.
   *
   *
   */
  education?: Maybe<GQLEducation>
  /**
   * Рост в миллиметрах.
   *
   *
   */
  height?: Maybe<Scalars['Int']['output']>
  /**
   * Место проживания.
   *
   *
   */
  homeStatus?: Maybe<GQLHomeStatus>
  /**
   * Языки, которые знает пользователь.
   *
   *
   */
  knownLanguages?: Maybe<Array<Scalars['Language']['output']>>
  /**
   * Кого ищу.
   *
   *
   */
  lookFor: Array<GQLLookFor>
  /**
   * Желаемый возраст.
   *
   *
   */
  lookForAge: GQLAgeRange
  /**
   * Материальный статус.
   *
   *
   */
  materialStatus?: Maybe<GQLMaterialStatus>
  /**
   * Ориентация.
   *
   *
   */
  orientation?: Maybe<GQLOrientation>
  /**
   * Отношение к курению.
   *
   *
   */
  smoking?: Maybe<GQLSmokingAttitude>
  /**
   * Вес в граммах.
   *
   *
   */
  weight?: Maybe<Scalars['Int']['output']>
}

/** Интерес пользователя. */
export type GQLPublicInterest = {
  __typename?: 'PublicInterest'
  /**
   * Идентификатор интереса.
   *
   *
   */
  interestId: Scalars['Int']['output']
  /**
   * Совпадает с интересом отправившего запрос пользователя.
   *
   *
   */
  matchMy?: Maybe<Scalars['Boolean']['output']>
  /**
   * Текст интереса.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Интересы пользователя. */
export type GQLPublicInterests = {
  __typename?: 'PublicInterests'
  /**
   * Интересы пользователя.
   *
   *
   */
  interests: GQLPublicInterestsConnection
  /**
   * Всего интересов.
   *
   *
   */
  interestsCount: Scalars['Int']['output']
}

/** Интересы пользователя. */
export type GQLPublicInterestsInterestsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit: Scalars['Int']['input']
}

/** Интересы пользователя. */
export type GQLPublicInterestsConnection = {
  __typename?: 'PublicInterestsConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLPublicInterest>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Мессенджер */
export type GQLPublicMessenger = {
  __typename?: 'PublicMessenger'
  /**
   * Reply rate в процентах
   *
   *
   */
  replyRate?: Maybe<Scalars['Int']['output']>
}

/** Фотографии из Омниальбома */
export type GQLPublicPhotosConnection = {
  __typename?: 'PublicPhotosConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLPhoto>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Информация о подаренном пользователю VIP. */
export type GQLPublicPresentVip = {
  __typename?: 'PublicPresentVip'
  /**
   * Количество дней, на которое был подарен VIP.
   *
   *
   */
  days: Scalars['Int']['output']
  /**
   * Является ли подарок анонимным.
   *
   *
   */
  hidden: Scalars['Boolean']['output']
  /**
   * Профиль подарившей анкеты.
   * Если подарок анонимный, профиль смогут увидеть только даритель и получатель
   * (для остальных пользователей вернётся null).
   *
   *
   */
  presenter?: Maybe<GQLPublicProfile>
  /**
   * Роль текущего пользователя по отношению к подаренному VIP.
   *
   *
   */
  viewerRole: GQLPublicPresentVipViewerRole
}

/** Роль текущего пользователя по отношению к подаренному VIP. */
export enum GQLPublicPresentVipViewerRole {
  Presenter = 'presenter',
  Stranger = 'stranger',
  Target = 'target',
}

/** Публичные данные пользователя. */
export type GQLPublicProfile = {
  __typename?: 'PublicProfile'
  /**
   * Возраст. Его может не быть из-за настроек приватности.
   *
   *
   */
  age?: Maybe<Scalars['Int']['output']>
  /**
   * Является ботом?
   *
   *
   */
  bot: Scalars['Boolean']['output']
  /**
   * Профиль знакомств.
   *
   *
   */
  dating: GQLPublicDatingProfile
  /**
   * Анкета удалена или заблокирована?
   *
   *
   */
  deleted: Scalars['Boolean']['output']
  /**
   * Пол.
   *
   *
   */
  gender: GQLGender
  /**
   * Гифты.
   *
   *
   */
  gifts: GQLGifts
  /**
   * Идентификатор пользователя.
   *
   *
   */
  id: Scalars['UserId']['output']
  /**
   * Инкогнито
   *
   *
   */
  incognito: Scalars['Boolean']['output']
  /**
   * Интересы пользователя.
   *
   *
   */
  interests: GQLPublicInterests
  /**
   * Сегодня день рождения пользователя
   *
   *
   */
  isBirthdayToday?: Maybe<Scalars['Boolean']['output']>
  /**
   * Местоположение пользователя.
   *
   *
   */
  location: GQLLocation
  /**
   * Мессенджер
   *
   *
   */
  messenger: GQLPublicMessenger
  /**
   * Имя.
   *
   *
   */
  name: Scalars['String']['output']
  /**
   * Онлайн. Его может не быть, если он скрыт пользователем.
   *
   *
   */
  online?: Maybe<GQLOnline>
  /**
   * Фотографии профиля.
   *
   *
   */
  photos: GQLProfilePhotos
  /**
   * Отношения с текущим пользователем.
   *
   *
   */
  relations: GQLProfileRelations
  /**
   * Шаринг.
   *
   *
   */
  sharing?: Maybe<GQLPublicSharing>
  /**
   * Id видео трансляции пользователя
   *
   *
   */
  streamId?: Maybe<Scalars['Int']['output']>
  /**
   * Данные по teamo.
   *
   *
   */
  teamo?: Maybe<GQLPublicTeamo>
  /**
   * ID фона.
   *
   *
   */
  themeId?: Maybe<Scalars['Int']['output']>
  /**
   * Путешествия.
   *
   *
   */
  travelAtlas: GQLPublicTravelAtlas
  /**
   * Данные о верификации пользователя.
   *
   *
   */
  verification: GQLVerification
  /**
   * VIP пользователя.
   *
   *
   */
  vip: GQLVip
  /**
   * Знак зодиака.
   *
   *
   */
  zodiacSign?: Maybe<GQLZodiacSign>
}

/** Шаринг. */
export type GQLPublicSharing = {
  __typename?: 'PublicSharing'
  /**
   * Виджеты.
   *
   *
   */
  widgets?: Maybe<GQLPublicWidgets>
}

/** Результаты психологического тестирования на teamo. */
export type GQLPublicTeamo = {
  __typename?: 'PublicTeamo'
  /**
   * Совместимость с анкетой текущего пользователя.
   *
   *
   */
  compatibility?: Maybe<Scalars['Int']['output']>
  /**
   * Результаты тестирования на teamo.
   *
   *
   */
  surveyResult?: Maybe<Array<GQLTeamoPeculiarity>>
}

/** Путешествия. */
export type GQLPublicTravelAtlas = {
  __typename?: 'PublicTravelAtlas'
  /**
   * Бейджи.
   *
   *
   */
  badges: GQLPublicTravelAtlasBadges
  /**
   * Посещённые страны.
   *
   *
   */
  visitedCountries: GQLPublicTravelAtlasVisitedCountries
}

/** Бейджи. */
export type GQLPublicTravelAtlasBadge = {
  __typename?: 'PublicTravelAtlasBadge'
  /**
   * Описание бейджа.
   *
   *
   */
  desc: Scalars['String']['output']
  /**
   * Идентификатор бейджа.
   *
   *
   */
  id: Scalars['Int']['output']
  /**
   * Иконка бейджа.
   *
   *
   */
  image: Scalars['String']['output']
  /**
   * Название бейджа.
   *
   *
   */
  name: Scalars['String']['output']
}

/** Бейджи. */
export type GQLPublicTravelAtlasBadges = {
  __typename?: 'PublicTravelAtlasBadges'
  /**
   * Бейджи.
   *
   *
   */
  badges: GQLPublicTravelAtlasBadgesConnection
  /**
   * Общее количество бейджей.
   *
   *
   */
  badgesCount: Scalars['Int']['output']
}

/** Бейджи. */
export type GQLPublicTravelAtlasBadgesBadgesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit: Scalars['Int']['input']
}

/** Бейджи. */
export type GQLPublicTravelAtlasBadgesConnection = {
  __typename?: 'PublicTravelAtlasBadgesConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLPublicTravelAtlasBadge>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Посещённые страны. */
export type GQLPublicTravelAtlasVisitedCountries = {
  __typename?: 'PublicTravelAtlasVisitedCountries'
  /**
   * Список стран.
   *
   *
   */
  visitedCountries: GQLPublicTravelAtlasVisitedCountriesConnection
  /**
   * Общее количество стран.
   *
   *
   */
  visitedCountriesCount: Scalars['Int']['output']
}

/** Посещённые страны. */
export type GQLPublicTravelAtlasVisitedCountriesVisitedCountriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  limit: Scalars['Int']['input']
}

/** Посещённые страны. */
export type GQLPublicTravelAtlasVisitedCountriesConnection = {
  __typename?: 'PublicTravelAtlasVisitedCountriesConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLPublicTravelAtlasVisitedCountry>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Посещённые страны. */
export type GQLPublicTravelAtlasVisitedCountry = {
  __typename?: 'PublicTravelAtlasVisitedCountry'
  /**
   * Страна.
   *
   *
   */
  country: GQLCountry
  /**
   * Дата посещения.
   *
   *
   */
  date: Scalars['String']['output']
}

/** Виджеты. */
export type GQLPublicWidgets = {
  __typename?: 'PublicWidgets'
  /**
   * Виджет This is me.
   *
   *
   */
  thisIsMe?: Maybe<GQLThisIsMeWidget>
}

/** Корневой запрос. */
export type GQLQuery = {
  __typename?: 'Query'
  /**
   * Дневники
   *
   * ### Вочдоги
   * * Проверяет различные блокировки Антиспама
   *   [blocked_tracker,blocked_ip,captcha]
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * blocked_tracker – Заблокирован трекер Антиспама!
   * * blocked_ip – IP адрес заблокирован Антиспамом!
   * * captcha – Требуется ввод капчи!
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  diaries: GQLDiaries
  /**
   * Дневники
   *
   * ### Вочдоги
   * * Проверяет различные блокировки Антиспама
   *   [blocked_tracker,blocked_ip,captcha]
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * blocked_tracker – Заблокирован трекер Антиспама!
   * * blocked_ip – IP адрес заблокирован Антиспамом!
   * * captcha – Требуется ввод капчи!
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  legacyDiaries: GQLLegacyDiaries
  /**
   * Данные текущего пользователя
   *
   * ### Вочдоги
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   *
   */
  my: GQLMy
  /**
   * Системные настройки.
   *
   *
   */
  systemSettings: GQLSystemSettings
  /**
   * UI.
   *
   *
   */
  ui: GQLUi
  /**
   * Публичные данные пользователя
   *
   * ### Вочдоги
   * * Проверяет различные блокировки Антиспама
   *   [blocked_tracker,blocked_ip,captcha]
   * * Требует, чтобы пользователь был не заблокирован (пропускает незарегов).
   *   [user_banned]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * limit_exceeded – Исчерпан лимит просмотров анкет.
   * * ignored_user_restriction – Запрашивающий в игноре.
   * * hidden_from_anon – Профиль скрыт от незарегов.
   * * blocked_tracker – Заблокирован трекер Антиспама!
   * * blocked_ip – IP адрес заблокирован Антиспамом!
   * * captcha – Требуется ввод капчи!
   * * user_deleted – Пользователь не должен быть удален.
   * * user_banned – Пользователь не должен быть забанен.
   *
   */
  user?: Maybe<GQLPublicProfile>
}

/** Корневой запрос. */
export type GQLQueryUserArgs = {
  hitType?: InputMaybe<Scalars['Int']['input']>
  userId: Scalars['UserId']['input']
}

/** Понравился вопрос. */
export type GQLQuestionLikeChatMessage = GQLChatMessage & {
  __typename?: 'QuestionLikeChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Посты из Дневников за последний месяц */
export type GQLRecentDiaryPostsConnection = {
  __typename?: 'RecentDiaryPostsConnection'
  /**
   * Данные.
   *
   *
   */
  nodes: Array<GQLDiaryPost>
  /**
   * Информация для постраничной навигации.
   *
   *
   */
  pageInfo: GQLPageInfo
}

/** Регион. */
export type GQLRegion = {
  __typename?: 'Region'
  /**
   *
   *
   *
   */
  country: GQLCountry
  /**
   *
   *
   *
   */
  id: Scalars['ID']['output']
  /**
   *
   *
   *
   */
  name: Scalars['String']['output']
}

/** Приветствие после регистрации. */
export type GQLRegistrationGreetingChatMessage = GQLChatMessage & {
  __typename?: 'RegistrationGreetingChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Удалить контакты. */
export type GQLRemoveContactsOutput = {
  __typename?: 'RemoveContactsOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Сообщение удалено. */
export type GQLRemovedChatMessage = GQLChatMessage & {
  __typename?: 'RemovedChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Сообщение удалено. */
export type GQLRemovedForRecipientChatMessage = GQLChatMessage & {
  __typename?: 'RemovedForRecipientChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Убрать из избранных. */
export type GQLRemoveFromFavOutput = {
  __typename?: 'RemoveFromFavOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Убрать из игнора. */
export type GQLRemoveFromIgnoreOutput = {
  __typename?: 'RemoveFromIgnoreOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Сбросить фон. */
export type GQLResetThemeOutput = {
  __typename?: 'ResetThemeOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/**
 * Изменить видимость посещённых стран.
 * Указываются идентификаторы только тех стран, для которых была изменена видимость.
 */
export type GQLSetCountriesVisibilityOutput = {
  __typename?: 'SetCountriesVisibilityOutput'
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Отношение к курению. */
export enum GQLSmokingAttitude {
  GiveUp = 'GiveUp',
  No = 'No',
  Sometimes = 'Sometimes',
  Yes = 'Yes',
}

/** Направление сортировки */
export enum GQLSortOrder {
  /** По возрастанию */
  Asc = 'ASC',
  /** По убыванию */
  Desc = 'DESC',
}

/** Space-time location. */
export type GQLSpaceTimeLocationRelation = {
  __typename?: 'SpaceTimeLocationRelation'
  /**
   * Текст.
   *
   *
   */
  text: Scalars['Lexeme']['output']
}

/** Сообщение-стикер. */
export type GQLStickerChatMessage = GQLChatMessage & {
  __typename?: 'StickerChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Идентификатор стикера.
   *
   *
   */
  stickerId: Scalars['Int']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Данные для комментария-стикера */
export type GQLStickerCommentDetails = GQLCommentDetails & {
  __typename?: 'StickerCommentDetails'
  /**
   * Идентификатор стикера
   *
   *
   */
  stickerId: Scalars['Int']['output']
  /**
   * Тип комментария
   *
   *
   */
  type: GQLCommentType
}

/** Системные настройки. */
export type GQLSystemSettings = {
  __typename?: 'SystemSettings'
  /**
   * Промо сервисы.
   *
   *
   */
  promoServices: GQLPromoServices
}

/** Данные по черте характера. */
export type GQLTeamoPeculiarity = {
  __typename?: 'TeamoPeculiarity'
  /**
   * Описание черты.
   *
   *
   */
  description: Scalars['String']['output']
  /**
   * ID черты.
   *
   *
   */
  id: Scalars['Int']['output']
  /**
   * Количество баллов.
   *
   *
   */
  points: Scalars['Int']['output']
  /**
   * Название черты.
   *
   *
   */
  title: Scalars['String']['output']
}

/** Сообщение. */
export type GQLTextChatMessage = GQLChatMessage & {
  __typename?: 'TextChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Даные для комментария-текста */
export type GQLTextCommentDetails = GQLCommentDetails & {
  __typename?: 'TextCommentDetails'
  /**
   * Текст комментария
   *
   *
   */
  text: Scalars['String']['output']
  /**
   * Тип комментария
   *
   *
   */
  type: GQLCommentType
}

/** Виджет This is me. */
export type GQLThisIsMeWidget = {
  __typename?: 'ThisIsMeWidget'
  /**
   * URL изображения.
   *
   *
   */
  url: Scalars['String']['output']
}

/** Время дня. */
export type GQLTime = {
  __typename?: 'Time'
  /**
   * Часы.
   *
   *
   */
  hours: Scalars['Int']['output']
  /**
   * Минуты.
   *
   *
   */
  minutes: Scalars['Int']['output']
  /**
   * Секунды.
   *
   *
   */
  seconds: Scalars['Int']['output']
}

/** UI. */
export type GQLUi = {
  __typename?: 'Ui'
  /**
   * Форматы URL картинок гифтов.
   *
   *
   */
  giftFormats: Array<GQLGiftImageFormatType>
  /**
   * Словари лексем.
   *
   *
   */
  glossary: GQLLexicalGlossary
  /**
   * Возможные варианты для текущего пользователя.
   *
   *
   */
  options: GQLUiOptions
}

/** Возможные варианты для текущего пользователя. */
export type GQLUiOptions = {
  __typename?: 'UiOptions'
  /**
   * Варианты профиля знакомств текущего пользователя.
   *
   * ### Вочдоги
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   *
   */
  datingProfile: GQLDatingProfileOptions
  /**
   * Варианты настроек для Дневников
   *
   * ### Вочдоги
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   *
   */
  diaries: GQLDiariesOptions
  /**
   * Варианты настроек для текущего пользователя.
   *
   * ### Вочдоги
   * * Требует только аутентификации пользователя, больше ничего не проверяет.
   *   [auth]
   * * Требует, чтобы пользователь был не удален (пропускает незарегов).
   *   [user_deleted]
   *
   * ### Возможные ошибки
   * * auth – Требуется авторизация.
   * * user_deleted – Пользователь не должен быть удален.
   *
   */
  userSettings: GQLUserSettingsOptions
}

/** Неподдерживаемое сообщение. */
export type GQLUnknownChatMessage = GQLChatMessage & {
  __typename?: 'UnknownChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Обновить координаты пользователя и переселить, если получится */
export type GQLUpdateUserCoordsOutput = {
  __typename?: 'UpdateUserCoordsOutput'
  /**
   * Местоположение пользователя.
   *
   *
   */
  location: GQLLocation
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Загрузить фото */
export type GQLUploadImageOutput = {
  __typename?: 'UploadImageOutput'
  /**
   *
   *
   *
   */
  imageId: Scalars['Int']['output']
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
}

/** Информация о теме пользователя. */
export type GQLUserProfileTheme = {
  __typename?: 'UserProfileTheme'
  /**
   * ID темы.
   *
   *
   */
  id: Scalars['Int']['output']
  /**
   * URL к PNG.
   *
   *
   */
  pngUrl: Scalars['String']['output']
  /**
   * URL к SVG.
   *
   *
   */
  svgUrl: Scalars['String']['output']
}

/** Варианты настроек пользователя. */
export type GQLUserSettingsOptions = {
  __typename?: 'UserSettingsOptions'
  /**
   * Список фонов.
   *
   *
   */
  themes: Array<GQLUserProfileTheme>
}

/** Использовать балл бесплатного просмотра анкеты в событиях. */
export type GQLUseSpecialPointOutput = {
  __typename?: 'UseSpecialPointOutput'
  /**
   * Профиль автора хита.
   *
   *
   */
  hitAuthorProfile?: Maybe<GQLPublicProfile>
  /**
   * Идентификатор автора хита.
   *
   *
   */
  hitAuthorProfileId?: Maybe<Scalars['Int']['output']>
  /**
   *
   *
   *
   */
  mutationId: Scalars['String']['output']
  /**
   * Сколько осталось баллов после использования.
   *
   *
   */
  pointsLeft: Scalars['Int']['output']
}

/** Данные о верификации пользователя. */
export type GQLVerification = {
  __typename?: 'Verification'
  /**
   * Является ли анкета пользователя подтверждённой (реал).
   *
   *
   */
  verifiedAccount: Scalars['Boolean']['output']
  /**
   * Есть ли подтвержденные фотографии?
   *
   *
   */
  verifiedPhotos: Scalars['Boolean']['output']
}

/** VIP пользователя. */
export type GQLVip = {
  __typename?: 'Vip'
  /**
   * Активен ли вип в данный момент?
   *
   *
   */
  active: Scalars['Boolean']['output']
  /**
   * Информация о подаренном пользователю VIP, если он был подарен, или null в противном случае
   *
   *
   */
  presentVip?: Maybe<GQLPublicPresentVip>
}

/** Сообщение о подарке VIP. */
export type GQLVipPresentChatMessage = GQLChatMessage & {
  __typename?: 'VipPresentChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Сообщение о продлении VIP. */
export type GQLVipProlongChatMessage = GQLChatMessage & {
  __typename?: 'VipProlongChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Подписка на VIP. */
export type GQLVipSubscription = {
  /**
   * Дата и время экспирации подписки.
   *
   *
   */
  expiresAt: Scalars['DateTime']['output']
}

/** Способ оплаты aka провайдер. */
export enum GQLVipSubscriptionPaymentType {
  /** Apple App Store */
  AppStore = 'APP_STORE',
  /** Google Play */
  GooglePlay = 'GOOGLE_PLAY',
}

/** Подмигивание. */
export type GQLWinkChatMessage = GQLChatMessage & {
  __typename?: 'WinkChatMessage'
  /**
   * Идентификатор сообщения.
   *
   *
   */
  messageId: Scalars['MessageId']['output']
  /**
   * «Моё»?
   *
   *
   */
  my: Scalars['Boolean']['output']
  /**
   * Новое?
   *
   *
   */
  new: Scalars['Boolean']['output']
  /**
   * Время отправки сообщения.
   *
   *
   */
  postedAt: Scalars['DateTime']['output']
  /**
   * Сообщение или лексема.
   *
   *
   */
  text: Scalars['String']['output']
}

/** Знак зодиака. */
export enum GQLZodiacSign {
  Bliznezi = 'bliznezi',
  Deva = 'deva',
  Kozerog = 'kozerog',
  Lev = 'lev',
  Oven = 'oven',
  Rak = 'rak',
  Ribi = 'ribi',
  Skorpion = 'skorpion',
  Strelez = 'strelez',
  Telez = 'telez',
  Vesi = 'vesi',
  Vodoley = 'vodoley',
}
